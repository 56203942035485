import React from "react";
import { useTranslation } from "react-i18next";
import Plot, { PlotParams } from "react-plotly.js";

import { de, fr } from "plotly.js-locales";

const supportedLocales = [de, fr] as const;
const supportedLocaleCodes = [
  "en",
  ...supportedLocales.map((l) => l.name),
] as const;
type PlotlyLocale = (typeof supportedLocaleCodes)[number];

export default function PrognosPlot(props: PlotParams): JSX.Element {
  const { i18n } = useTranslation();

  // TODO thousand separator and decimal point

  const extLocale = i18n.languages[0] as PlotlyLocale;
  const locale = supportedLocaleCodes.includes(extLocale) ? extLocale : "en";

  const handleLegendClick = useLegendClickCallback(props);

  return (
    <Plot
      {...props}
      onLegendClick={handleLegendClick}
      //useResizeHandler
      config={{
        ...props.config,
        responsive: true,
        locale,
        locales: {
          ...supportedLocales.reduce((a, v) => ({ ...a, [v.name]: v }), {}),
          ...props.config?.locales,
        },
      }}
    />
  );
}

function useLegendClickCallback(
  props: PlotParams
): ((event: Readonly<Plotly.LegendClickEvent>) => boolean) | undefined {
  const { onLegendClick, onLegendDoubleClick } = props;

  const timeoutRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  if (onLegendClick && onLegendDoubleClick) {
    return (e) => {
      if (e.event.detail === 1) {
        timeoutRef.current = setTimeout(() => {
          return onLegendClick(e);
        }, 150);
      }
      if (e.event.detail === 2) {
        clearTimeout(timeoutRef.current);
        return onLegendDoubleClick(e);
      }
      return false;
    };
  }

  return onLegendClick;
}
