import { useDatasetMessagesCallback } from "../../DataStore/pages/Import/hooks/useDatasetMessagesCallback";
import { useRunMessagesCallback } from "../pages/Run/hooks/useRunMessagesCallback";
import useAggregatesCalcMessageCallback from "./useAggregatesCalcMessageCallback";
import useDirtyAggregatesMessageCallback from "./useDirtyAggregatesMessageCallback";
import useExportMessageCallback from "./useExportMessageCallback";
import useImportMessageCallback from "./useImportMessageCallback";
import useMessageHook from "./useMessageHook";
import useOutlierDetectionMessageCallback from "./useOutlierDetectionMessageCallback";

/**
 * Catches all the solution-related messages using specific hooks that react accordingly.
 *
 * Place to register all solution-related message hooks.
 */
export default function useSolutionMessages() {
  // model runs
  const runTasksMessagesCallback = useRunMessagesCallback();
  useMessageHook(runTasksMessagesCallback);

  // imports
  const datasetMessagesCallback = useDatasetMessagesCallback();
  useMessageHook(datasetMessagesCallback);

  // prompt to recalculate aggregates
  const dirtyAggregatesCallback = useDirtyAggregatesMessageCallback();
  useMessageHook(dirtyAggregatesCallback);

  // aggregates recalculation progress
  const aggregatesCalcMessagesCallback = useAggregatesCalcMessageCallback();
  useMessageHook(aggregatesCalcMessagesCallback);

  const outlierDetectionMessageCallback = useOutlierDetectionMessageCallback();
  useMessageHook(outlierDetectionMessageCallback);

  const exportMessageCallback = useExportMessageCallback();
  useMessageHook(exportMessageCallback);

  const importMessageCallback = useImportMessageCallback();
  useMessageHook(importMessageCallback);
}
