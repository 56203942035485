import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames";

import {
  CircleStackIcon,
  Cog6ToothIcon,
  HomeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../api/solutions";
import { getAnalyzerPath } from "../apps/Analyzer/routes/analyzer";
import { getDataStorePath } from "../apps/DataStore/routes/dataStore";
import { getDemandPath } from "../apps/Demand/routes/demand";
import { getPrognosPath } from "../apps/PrognosAI/routes/prognos";
import { getSolutionSettingsPath } from "../apps/PrognosAI/routes/solutionSettings";
import { getUserSettingsPath } from "../apps/PrognosAI/routes/userSettings";
import { HeroIcon } from "../models/primitives";
import { getSolutionPath } from "../routes/solutions";
import Card from "./Card";

interface AppsProps {
  size?: "medium" | "large";
  className?: string;
  omitApps?: string[];
}

export default function Apps(props: AppsProps): JSX.Element {
  const { size = "medium", className, omitApps = [] } = props;

  const apps = useSuiteApps();

  return (
    <ul className={classNames("", className)}>
      {apps
        .filter((app) => !omitApps.includes(app.key))
        .map((app) => (
          <li key={app.link}>
            <AppIconLink
              to={app.link}
              label={app.label}
              size={size}
              icon={app.icon}
            />
          </li>
        ))}
    </ul>
  );
}

export function useSuiteApps(): {
  key: string;
  link: string;
  label: string;
  icon?: HeroIcon;
}[] {
  const [, solutionId] = useSolution();
  const { t } = useTranslation();
  return [
    {
      key: "home",
      link: getSolutionPath(solutionId),
      label: t("Home"),
      icon: HomeIcon,
    },
    {
      key: "dataStore",
      link: getDataStorePath(solutionId),
      label: t("Data store"),
      icon: CircleStackIcon,
    },
    { key: "analyzer", link: getAnalyzerPath(solutionId), label: "Analyzer" },
    { key: "prognosAI", link: getPrognosPath(solutionId), label: "Prognos AI" },
    { key: "demand", link: getDemandPath(solutionId), label: "Demand" },
    {
      key: "projectSettings",
      link: getSolutionSettingsPath(solutionId),
      label: t("Project settings"),
      icon: Cog6ToothIcon,
    },
    {
      key: "userSettings",
      link: getUserSettingsPath(solutionId, undefined),
      label: t("User settings"),
      icon: UserIcon,
    },
  ];
}

interface AppIconLinkProps {
  size: "medium" | "large";
  to: string;
  label: string;
  icon?: HeroIcon;
}

function AppIconLink(props: AppIconLinkProps): JSX.Element {
  const { size, to, label, icon: Icon } = props;
  return (
    <Link
      to={to}
      className="no-underline text-black text-center space-y-2 group hover:text-gray-700"
    >
      <Card
        paddings={false}
        className={classNames(
          "flex items-center justify-center group-hover:bg-blue-50",
          size === "medium" ? "w-16 h-16" : "",
          size === "large" ? "w-24 h-24" : ""
        )}
        style={{
          fontSize: size === "large" ? 48 : size === "medium" ? 32 : 24,
        }}
      >
        {!Icon && label[0]}
        {Icon && (
          <Icon
            className={classNames(
              "text-gray-400",
              size === "medium" ? "w-8 h-8" : "",
              size === "large" ? "w-12 h-12" : ""
            )}
          />
        )}
      </Card>
      <p
        className={classNames(
          "text-gray-600 group-hover:text-gray-700 group-hover:underline",
          size === "medium" ? "text-xs w-16" : "",
          size === "large" ? "text-sm w-24" : ""
        )}
      >
        {label}
      </p>
    </Link>
  );
}
