import React from "react";

import { SolutionDetail } from "../apps/PrognosAI/models/solution";

export default function useDocumentTitle(
  title: string | undefined,
  solution?: SolutionDetail,
  delay = false
) {
  React.useEffect(() => {
    const newTitle = getTitle(title, solution);
    document.title = newTitle;

    let timer: NodeJS.Timeout | undefined;
    if (delay) {
      timer = setTimeout(() => (document.title = newTitle), 200);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [title, solution, window.location.href]);
}

const SEPARATOR = " - ";

function getTitle(
  title: string | undefined,
  solution?: SolutionDetail
): string {
  const result = ["TIS Prognos AI"];

  if (solution) {
    result.unshift(solution.name);
  }

  if (title) {
    result.unshift(title);
  }

  return result.join(SEPARATOR);
}
