import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  ArrowDownOnSquareIcon,
  ClockIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, { SidebarItem } from "../../../../components/sidebar/Sidebar";
import BasicLayout from "../../../PrognosAI/components/layouts/BasicLayout";
import useSolutionMessages from "../../../PrognosAI/hooks/useSolutionMessages";
import { getBizHoursPath } from "../../routes/businessHours";
import { getDataStorePath } from "../../routes/dataStore";
import { getImportPath } from "../../routes/import";

interface DataStoreLayoutProps {
  children?: React.ReactNode;
}

export default function DataStoreLayout({ children }: DataStoreLayoutProps) {
  const [{ data: solution }, solutionId] = useSolution();

  // TODO proper data store messages
  useSolutionMessages();

  // TODO general items
  //const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useDatasetMenuItems(solutionId);

  const topNavigation = [solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={undefined}
      solution={solution}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}

function useDatasetMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();

  const topItems = [
    {
      key: "dashboard",
      name: t("Dashboard"),
      href: getDataStorePath(solutionId),
      icon: HomeIcon,
      current: location.pathname === getDataStorePath(solutionId),
    },
    {
      key: "import",
      name: t("Data import"),
      href: getImportPath(solutionId),
      icon: ArrowDownOnSquareIcon,
      current: location.pathname.startsWith(getImportPath(solutionId)),
    },
    {
      key: "businessHours",
      name: t("Business Hours"),
      href: getBizHoursPath(solutionId),
      icon: ClockIcon,
      current: location.pathname.startsWith(getBizHoursPath(solutionId)),
    },
  ];

  return [topItems, []];
}
