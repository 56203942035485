import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../api";
import { activeAggregateCalcTasksQuery } from "../apps/PrognosAI/api/tasks";
import {
  PaginatedResponse,
  paginatedResponse,
} from "../apps/PrognosAI/models/response";
import {
  Solution,
  SolutionDetail,
  SolutionDetailZod,
  SolutionDraft,
  SolutionExtraInfo,
  SolutionExtraInfoZod,
  SolutionFirstSteps,
  SolutionFirstStepsZod,
  SolutionMetrics,
  SolutionMetricsZod,
  SolutionUsedIdentifiers,
  SolutionUsedIdentifiersZod,
  SolutionZod,
} from "../apps/PrognosAI/models/solution";
import { getSolutionPath, getSolutionsPath } from "../routes/solutions";

async function getSolutions(
  options: EndpointOptions = {}
): Promise<PaginatedResponse<SolutionExtraInfo[]>> {
  const query = getQueryString(options);
  const solutions = paginatedResponse(SolutionExtraInfoZod.array()).parse(
    (await api.get(`/Solutions?${query}`)).data
  );
  return solutions;
}

const SOLUTIONS_PREFIX = ["solutions"];

export const solutionsQuery = (options?: EndpointOptions) => ({
  queryKey: [...SOLUTIONS_PREFIX, "list", ...(options ? [options] : [])],
  queryFn: () => getSolutions(options),
});

async function getSolution(
  solutionId: string | number
): Promise<SolutionDetail> {
  const response = await api.get(`/Solutions/${solutionId}`);
  const data = SolutionDetailZod.parse(response.data);

  // renaming the root partition to "all"
  const rootPartition = data.partitions.find(
    (p) => p.parentPartitionId === null
  );
  if (rootPartition) {
    rootPartition.name = "all";
  }

  return data;
}

export const solutionQuery = (solutionId: string | number | undefined) => ({
  queryKey: ["solution", solutionId?.toString() ?? ""],
  queryFn: () => (solutionId ? getSolution(solutionId) : undefined),
  enabled: !!solutionId,
});

export function useSolution(): [
  UseQueryResult<SolutionDetail | undefined, Error>,
  string,
  number,
] {
  const { solutionId } = useParams();
  const numericSolutionId = parseInt(solutionId ?? "");
  if (!solutionId || isNaN(numericSolutionId)) {
    throw new Error("URL param :solutionId not provided or is not an integer.");
  }

  const solutionQueryData = useQuery(solutionQuery(solutionId));

  return [solutionQueryData, solutionId, numericSolutionId];
}

async function createSolution(solution: SolutionDraft): Promise<Solution> {
  const response = await api.post(`/Solutions`, solution);
  return SolutionZod.parse(response.data);
}

export const useCreateSolution = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (solution: SolutionDraft) => createSolution(solution),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: SOLUTIONS_PREFIX });
      navigate(getSolutionPath(data.solutionId));
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: SOLUTIONS_PREFIX });
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function updateSolution(
  solutionId: string,
  patch: Partial<SolutionDetail>
): Promise<Solution> {
  const response = await api.patch(`/Solutions/${solutionId}`, patch);
  return SolutionZod.parse(response.data);
}

export const useEditSolution = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<SolutionDetail>) =>
      updateSolution(solutionId, patch),
    onSuccess: (newSolution, patch) => {
      queryClient.setQueryData<SolutionDetail>(
        solutionQuery(solutionId).queryKey,
        (oldSolution) =>
          oldSolution
            ? { ...oldSolution, ...patch, ...newSolution }
            : oldSolution
      );
      queryClient.invalidateQueries({ queryKey: SOLUTIONS_PREFIX });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: SOLUTIONS_PREFIX });
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteSolution(solutionId: string) {
  return api.delete(`/Solutions/${solutionId}`);
}

export const useDeleteSolution = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => deleteSolution(solutionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SOLUTIONS_PREFIX });
      queryClient.removeQueries(solutionQuery(solutionId));
      navigate(getSolutionsPath());
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function calculateAggregates(solutionId: string | number) {
  return api.post(`/Solutions/${solutionId}/CalculateAggregates`);
}

export const useCalculateAggregates = (solutionId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: () => calculateAggregates(solutionId),
    onSuccess: () => {
      queryClient.invalidateQueries(
        activeAggregateCalcTasksQuery(solutionId.toString())
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function getSolutionFirstSteps(
  solutionId: string
): Promise<SolutionFirstSteps> {
  return SolutionFirstStepsZod.parse(
    (await api.get(`/Solutions/${solutionId}/FirstSteps`)).data
  );
}

export const solutionFirstStepsQuery = (solutionId: string) => ({
  queryKey: ["solutionFirstSteps", solutionId],
  queryFn: () => getSolutionFirstSteps(solutionId),
  gcTime: 0,
  staleTime: 0,
});

async function getSolutionMetrics(
  solutionId: string | number
): Promise<SolutionMetrics> {
  return SolutionMetricsZod.parse(
    (await api.get(`/Solutions/${solutionId}/Metrics`)).data
  );
}

export const solutionMetricsQuery = (solutionId: string | number) => ({
  queryKey: ["solutionMetrics", solutionId.toString()],
  queryFn: () => getSolutionMetrics(solutionId),
  // maybe invalidate with import only?
  gcTime: 0,
  staleTime: 0,
});

async function getSolutionUsedIdentifiers(): Promise<
  SolutionUsedIdentifiers[]
> {
  return SolutionUsedIdentifiersZod.array().parse(
    (await api.get(`/Solutions/UsedIdentifiers`)).data
  );
}

export const solutionUsedIdentifiersQuery = () => ({
  queryKey: [...SOLUTIONS_PREFIX, "usedIdentifiers"],
  queryFn: () => getSolutionUsedIdentifiers(),
});
