import React from "react";
import { useTranslation } from "react-i18next";

import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import Checkbox from "../../../../../components/form/Checkbox";
import { HeroIcon } from "../../../../../models/primitives";
import { Partition, ViewMode } from "../../../models/partition";

export type PartitionNode = {
  partition: Partition;
  parent?: Partition;
  children: PartitionNode[];
};

export type PartitionAction = {
  key: string;
  icon: HeroIcon;
  title: string;
  onClick(partition: Partition): void;
};

interface SelectablePartitionNodeProps {
  ancestors: PartitionNode[];
  partitionNode: PartitionNode;
  view: ViewMode;
  onChange(partitionIds: number[]): void;
  defaultOpen?: boolean;
  selectedPartitionIds: number[];
  actions?: PartitionAction[];
  search?: string;
  treeId?: string;
  selectSubtrees: boolean;
  disabled?: boolean;
}

export default function SelectablePartitionNode(
  props: SelectablePartitionNodeProps
): JSX.Element | null {
  const { partitionNode, selectedPartitionIds, ancestors, view } = props;
  const { defaultOpen = false, selectSubtrees, disabled = false } = props;
  const { actions = [], search = "", treeId = "", onChange } = props;

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(defaultOpen);

  const { partition, children } = partitionNode;

  const isLeaf = children.length === 0;
  const allDescendants = getAllDescendantNodes(children);
  const selectedDescendants = allDescendants.filter(({ partition }) =>
    selectedPartitionIds.some((id) => id === partition.partitionId)
  );
  const allLeafs = allDescendants.filter((p) => p.children.length === 0);
  const selectedLeafs = allLeafs.filter((p) =>
    selectedPartitionIds.includes(p.partition.partitionId)
  );

  const isSelected = selectedPartitionIds.includes(partition.partitionId);

  const handleNodeToggle = () => {
    const { partitionId } = partition;

    if (isSelected) {
      onChange(selectedPartitionIds.filter((id) => id !== partitionId));
    } else {
      onChange([...selectedPartitionIds, partitionId]);
    }
  };

  const containsSearch =
    !!search && partition.name.toLowerCase().includes(search.toLowerCase());
  const descendantContainsSearch =
    !!search &&
    allDescendants.some((d) =>
      d.partition.name.toLowerCase().includes(search.toLowerCase())
    );
  const ancestorContainsSearch =
    !!search &&
    ancestors.some((d) =>
      d.partition.name.toLowerCase().includes(search.toLowerCase())
    );

  const isVisible =
    // search-related
    (!search ||
      containsSearch ||
      descendantContainsSearch ||
      ancestorContainsSearch) &&
    // view-related
    (!isLeaf || view !== "aggregates");

  if (!isVisible) {
    return null;
  }

  const handleCheckChange = getCheckCallback({
    partitionNode,
    search,
    view,
    allDisplayableBySearch: !search || containsSearch || ancestorContainsSearch,
    selectedPartitionIds,
    onChange,
  });

  const forceOpen = open || descendantContainsSearch;

  const sortedChildren = [...children];
  sortedChildren.sort((a, b) =>
    a.partition.name.localeCompare(b.partition.name)
  );

  const { selected, total, extra } = getSelectedStats(
    isLeaf,
    isSelected,
    selectedLeafs,
    allLeafs,
    selectedDescendants,
    allDescendants,
    view
  );

  return (
    <Node
      open={forceOpen}
      onOpenToggle={() => setOpen((prev) => !prev)}
      onCheckChange={handleCheckChange}
      treeId={treeId}
      isChecked={(isLeaf && isSelected) || (!isLeaf && selected === total)}
      isSemiChecked={!isLeaf && selected > 0}
      isLeaf={isLeaf}
      partition={partition}
      disabled={disabled || (isLeaf && selectSubtrees)}
      info={
        <>
          {(!!selected || !!extra) && (
            <span className="text-gray-400 text-xs">
              {selected}/{total}
              {!!extra && <> {t("(+{{count}})", { count: extra })}</>}
            </span>
          )}
          <span>
            {actions.map((action) => (
              <button
                key={action.key}
                id={`${treeId}-${partition.partitionId}-action-${action.key}`}
                className="w-5 h-5 text-gray-300 hover:text-gray-400"
                title={action.title}
                onClick={() => action.onClick(partition)}
              >
                <action.icon />
              </button>
            ))}
          </span>
        </>
      }
    >
      {forceOpen && !isLeaf && (
        <ol className="ml-6">
          {view !== "leaves" && !selectSubtrees && (
            <Node
              open={false}
              isLeaf={true}
              onCheckChange={handleNodeToggle}
              treeId={`aggCheckbox-${treeId}`}
              isChecked={selectedPartitionIds.includes(partition.partitionId)}
              partition={partition}
              disabled={disabled}
              info={
                <span className="text-gray-400 text-xs">
                  ({t("aggregate")})
                </span>
              }
            />
          )}
          {sortedChildren.map((child) => (
            <SelectablePartitionNode
              key={child.partition.partitionId}
              ancestors={[...ancestors, partitionNode]}
              partitionNode={child}
              selectedPartitionIds={selectedPartitionIds}
              actions={actions}
              search={search}
              view={view}
              selectSubtrees={selectSubtrees}
              disabled={disabled}
              onChange={onChange}
            />
          ))}
        </ol>
      )}
    </Node>
  );
}

interface NodeProps {
  treeId: string | number;
  partition: Partition;
  isLeaf: boolean;
  isChecked: boolean;
  isSemiChecked?: boolean;
  open?: boolean;
  onOpenToggle?(): void;
  onCheckChange(e: React.ChangeEvent<HTMLInputElement>): void;
  children?: React.ReactNode;
  info?: React.ReactNode;
  disabled?: boolean;
}

function Node(props: NodeProps): JSX.Element {
  const { treeId, partition } = props;
  const { isLeaf, isChecked, isSemiChecked = false } = props;
  const { open, onOpenToggle, onCheckChange } = props;
  const { info, children, disabled } = props;

  const { t } = useTranslation();

  return (
    <li>
      <div className="inline-block text-sm space-x-1 w-full">
        <span className="inline-block w-5 text-center">
          {!isLeaf && onOpenToggle && (
            <button
              id={`${treeId}-${partition.partitionId}-openCloseButton`}
              className="w-5 h-5 text-gray-500"
              onClick={onOpenToggle}
            >
              {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </button>
          )}
        </span>
        <span className="inline-block w-5 text-center">
          <Checkbox
            id={`${treeId}-check-part-${partition.partitionId}`}
            checked={isChecked || isSemiChecked}
            variant={isChecked ? "primary" : "secondary"}
            disabled={disabled}
            className="disabled:bg-gray-200 disabled:hover:bg-gray-200"
            title={
              disabled
                ? t(
                    "Selecting single planning areas is not possible with hierarchical reconcilliation."
                  )
                : undefined
            }
            onChange={onCheckChange}
          />
        </span>
        <label
          htmlFor={`${treeId}-check-part-${partition.partitionId}`}
          className="pl-0.5 text-sm"
        >
          {partition.name}
        </label>
        {info}
      </div>
      {children}
    </li>
  );
}

type CheckCallbackProps = {
  partitionNode: PartitionNode;
  search: string;
  view: ViewMode;
  allDisplayableBySearch: boolean;
  selectedPartitionIds: number[];
  onChange(partitionIds: number[]): void;
};

function getCheckCallback(props: CheckCallbackProps) {
  const { partitionNode, search, view, allDisplayableBySearch } = props;
  const { selectedPartitionIds, onChange } = props;

  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const displayableSubNodes = getDisplayableSubNodes(
      partitionNode,
      search,
      view,
      allDisplayableBySearch
    );

    if (e.target.checked) {
      onChange([
        ...new Set(
          selectedPartitionIds.concat(
            displayableSubNodes.map((d) => d.partition.partitionId)
          )
        ),
      ]);
    } else {
      onChange(
        selectedPartitionIds.filter(
          (id) =>
            !displayableSubNodes.some((d) => d.partition.partitionId === id)
        )
      );
    }
  };
}

function getSelectedStats(
  isLeaf: boolean,
  isSelected: boolean,
  selectedLeaves: PartitionNode[],
  allLeaves: PartitionNode[],
  selectedDescentants: PartitionNode[],
  allDescendants: PartitionNode[],
  view: ViewMode
): { selected: number; total: number; extra?: number } {
  if (isLeaf) {
    return { selected: 0, total: 0 };
  }

  const delta = isSelected ? 1 : 0;

  const totalDescendants = allDescendants.length + 1;
  const totalLeaves = allLeaves.length;
  const selectedDescendantsCount = selectedDescentants.length + delta;

  switch (view) {
    case "all":
      return {
        selected: selectedDescendantsCount,
        total: totalDescendants,
      };
    case "aggregates":
      return {
        selected: selectedDescendantsCount - selectedLeaves.length,
        total: totalDescendants - totalLeaves,
        extra: selectedLeaves.length,
      };
    case "leaves":
      return {
        selected: selectedLeaves.length,
        total: totalLeaves,
        extra: selectedDescendantsCount - selectedLeaves.length,
      };
  }
}

function getDisplayableSubNodes(
  root: PartitionNode,
  search: string,
  view: ViewMode,
  allDisplayableBySearch: boolean
): PartitionNode[] {
  const isDisplayableBySearch =
    allDisplayableBySearch ||
    root.partition.name.toLowerCase().includes(search.toLowerCase());

  const isDisplayableByView =
    view === "all" ||
    (view === "leaves" && root.children.length === 0) ||
    (view === "aggregates" && root.children.length > 0);

  const isDisplayable = isDisplayableBySearch && isDisplayableByView;

  return root.children
    .flatMap((child) =>
      getDisplayableSubNodes(child, search, view, isDisplayable)
    )
    .concat(isDisplayable ? [root] : []);
}

function getAllDescendantNodes(children: PartitionNode[]): PartitionNode[] {
  const nodes: PartitionNode[] = [];

  for (const child of children) {
    nodes.push(child);
    nodes.push(...getAllDescendantNodes(child.children));
  }

  return nodes;
}
