import { z } from "zod";

const ModelRunDependencyZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("RunResult"),
  type: z.literal("RunResult"),
});
export type ModelRunDependency = z.infer<typeof ModelRunDependencyZod>;

export const ModelDeletionErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("PgnModel"),
  type: z.literal("PgnModel"),
  valuesCount: z.number(),
  values: ModelRunDependencyZod.array(),
});

export const RunDeletionErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("RunConfig"),
  type: z.literal("RunConfig"),
  valuesCount: z.number(),
  values: ModelRunDependencyZod.array(),
});

export const datasetDependencyTypes = [
  "RunConfig",
  "Chart",
  "RunResult",
  "BusinessHourSet",
  "InfluencingFactor",
] as const;
const DataImportConfigDependencyTypeZod = z.enum(datasetDependencyTypes);
export type DataImportConfigDependencyType = z.infer<
  typeof DataImportConfigDependencyTypeZod
>;

const DataImportConfigDependencyZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.string(),
  type: DataImportConfigDependencyTypeZod,
});
export type DataImportConfigDependency = z.infer<
  typeof DataImportConfigDependencyZod
>;

export const DataImportConfigErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("DataImportConfig"),
  type: z.literal("DataImportConfig"),
  valuesCount: z.number(),
  values: DataImportConfigDependencyZod.array(),
});
export type DataImportConfigError = z.infer<typeof DataImportConfigErrorZod>;
