import React from "react";

import Apps from "../../components/Apps";
import HeroBreadcrumbs from "../../components/HeroBreadcrumbs";

export default function AppsOverview(): JSX.Element {
  return (
    <>
      <HeroBreadcrumbs path={[]} />
      <div className="p-4 w-full grow flex items-center justify-center">
        <Apps size="large" className="flex gap-8" omitApps={["home"]} />
      </div>
    </>
  );
}
