import React from "react";
import { Navigate, RouteObject } from "react-router";
import { useParams } from "react-router-dom";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getPrognosPath } from "./prognos";

const Model = lazyWithRetry(() => import("../pages/Model/Model"));
const Models = lazyWithRetry(() => import("../pages/Models/Models"));
const General = lazyWithRetry(() => import("../pages/Model/sections/General"));
const InfluencingFactors = lazyWithRetry(
  () => import("../pages/Model/sections/InfluencingFactors")
);
const Advanced = lazyWithRetry(
  () => import("../pages/Model/sections/Advanced")
);
const Algorithm = lazyWithRetry(
  () => import("../pages/Model/sections/Algorithm")
);
const RunConfigs = lazyWithRetry(
  () => import("../pages/Model/sections/RunConfigs")
);

// required because the autosave messes up otherwise
function AlgorithmWithKey(): JSX.Element {
  const { algorithmConfigId } = useParams();
  return <Algorithm key={algorithmConfigId} />;
}

const MODELS_PATH = "models";

export const modelPages = [
  "general",
  "influencingFactors",
  "advanced",
  "runConfigs",
] as const;
export const ModelPageZod = z.enum(modelPages);
export type ModelPage = z.infer<typeof ModelPageZod>;
export type ExtendedModelPage = ModelPage | `algorithms/${number}`;

const PageEnum = ModelPageZod.Enum;

export const modelsRoute: RouteObject = {
  path: MODELS_PATH,
  children: [
    { index: true, element: <Models /> },
    {
      path: ":modelId",
      element: <Model />,
      children: [
        { index: true, element: <Navigate to={PageEnum.general} replace /> },
        { path: PageEnum.general, element: <General /> },
        { path: PageEnum.influencingFactors, element: <InfluencingFactors /> },
        { path: PageEnum.advanced, element: <Advanced /> },
        {
          path: "algorithms/:algorithmConfigId",
          element: <AlgorithmWithKey />,
        },
        { path: PageEnum.runConfigs, element: <RunConfigs /> },
      ],
    },
  ],
};

export function getModelsPath(solutionId: string | number): string {
  return `${getPrognosPath(solutionId)}/${MODELS_PATH}`;
}

export function getModelDetailPath(
  solutionId: string | number,
  modelId: string | number
): string {
  return `${getModelsPath(solutionId)}/${modelId}`;
}
