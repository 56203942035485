import React from "react";

import { Aggregate } from "../models/aggregate";
import { Invalid } from "../models/primitives";
import ButtonGroup from "./buttons/ButtonGroup";

interface LargeTimescaleButtonsProps {
  id: string;
  label?: string;
  options: Aggregate[];
  value: Aggregate | Invalid | null;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (scale: Aggregate) => void;
}

export default function LargeTimescaleButtons(
  props: LargeTimescaleButtonsProps
) {
  const { id, label, options, value, disabled, isLoading, onChange } = props;

  const labelEl = label ? (
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
  ) : null;

  if (isLoading) {
    return (
      <div className="w-full">
        {labelEl}
        <div
          id={id}
          className="animate-pulse bg-gray-200 rounded py-2 pl-3 pr-10 w-full h-[38px]"
        ></div>
      </div>
    );
  }

  const realValue = value !== "Invalid" ? value : null;

  return (
    <div className="w-full">
      {labelEl}
      <ButtonGroup
        id={id}
        wrapperClassName={"w-full"}
        options={options.map((key) => ({ key }))}
        active={realValue ?? undefined}
        disabled={disabled}
        onClick={onChange}
      />
    </div>
  );
}
