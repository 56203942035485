import React from "react";
import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";

import DateTime from "../../apps/PrognosAI/components/formatter/DateTime";
import Number from "../../apps/PrognosAI/components/formatter/Number";
import LinkTableCell from "../../apps/PrognosAI/components/table/LinkTableCell";
import TableCell from "../../apps/PrognosAI/components/table/TableCell";
import TableRow from "../../apps/PrognosAI/components/table/TableRow";
import { compareScales } from "../../models/aggregate";
import Checkbox from "../form/Checkbox";
import { Column, DataTableGenericRowProps, Row, TableDataValue } from "./types";

interface DataTableRowProps<C extends Column[], R extends Row<C>>
  extends DataTableGenericRowProps<C, R> {
  id?: string;
  row: R;
  cellPadding?: string;
  checkbox?: {
    checked: boolean;
    variant: "primary" | "secondary";
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
}

export default function DataTableRow<C extends Column[], R extends Row<C>>(
  props: DataTableRowProps<C, R>
): JSX.Element {
  const { id, columns, row } = props;
  const { linkKey, actions, rowClassName, cellPadding = "" } = props;
  const { onRowClick } = props;
  const { checkbox } = props;

  const TableCellComponent =
    linkKey && typeof row[linkKey] !== "undefined" ? LinkTableCell : TableCell;

  const { t } = useTranslation();

  const className = rowClassName
    ? typeof rowClassName === "function"
      ? rowClassName(row)
      : rowClassName
    : undefined;

  return (
    <TableRow
      id={id}
      className={classNames(
        onRowClick ? "cursor-pointer" : "",
        className ?? ""
      )}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
    >
      {!!checkbox && (
        <TableCell>
          <Checkbox id={id ? `${id}-checkbox` : undefined} {...checkbox} />
        </TableCell>
      )}
      {columns.map((col) => (
        <TableCellComponent
          key={`${col.key}-${col.type}-${col.label}`}
          className={classNames(
            typeof col.shrink !== "undefined" ? "max-w-xs" : "",
            col.type === "number" || col.align === "right" ? "text-right" : "",
            col.align === "center" || (col.content === "icon" && !col.align)
              ? "text-center"
              : "",
            col.content === "icon" ? "py-3" : "",
            col.highlighted ? "font-medium text-gray-900" : "text-gray-500",
            col.className ?? "",
            cellPadding
          )}
          link={row[linkKey]?.toString() ?? ""}
          shrink={col.shrink ?? "truncate"}
          noPadding={!!cellPadding}
        >
          {renderDataType(
            (row as { [key: string]: TableDataValue })[col.key],
            col,
            t
          )}
        </TableCellComponent>
      ))}
      {actions && (
        <TableCell className="text-right space-x-1">
          {actions.map((action, index) => {
            if (typeof action === "function") {
              action = action(row);
            }

            if (action === null) {
              return null;
            }

            if (!("title" in action)) {
              return <React.Fragment key={index}>{action}</React.Fragment>;
            }

            const { title, onClick, icon: Icon } = action;

            return (
              <button
                key={title}
                id={`${id}-action-idx${index}`}
                title={title}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(row);
                }}
              >
                <Icon className="text-gray-400 hover:text-gray-600 h-5 w-5" />
              </button>
            );
          })}
        </TableCell>
      )}
    </TableRow>
  );
}

const renderDataType = (
  value: TableDataValue,
  col: Column,
  t: TFunction
): JSX.Element => {
  if (typeof value === "undefined" || value === null) {
    return <></>;
  }

  const { options } = col;

  switch (col.type) {
    case "enum":
    case "string":
      return <>{value}</>;
    case "number": {
      const parsedVal = parseFloat(value as string);
      if (isNaN(parsedVal)) {
        return <>{value}</>;
      }
      return (
        <Number
          value={parsedVal}
          precision={options?.precision ?? "max"}
          unit={options?.unit}
        />
      );
    }
    case "date": {
      const scale = options?.dateScale ?? "1D";
      return (
        <DateTime
          value={typeof value === "string" ? new Date(value) : (value as Date)}
          aggregate={scale}
          timezone={
            compareScales(scale, ">=", "1D") ? "UTC" : options?.timezone
          }
        />
      );
    }
    case "datetime":
      return (
        <DateTime
          value={value as string}
          aggregate="30m"
          timezone={options?.timezone}
        />
      );
    case "boolean":
      return (
        <>
          {value
            ? (options?.yesLabel ?? t("Yes"))
            : (options?.noLabel ?? t("No"))}
        </>
      );
  }
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
