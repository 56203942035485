import React from "react";

import { useSolution } from "../../../../api/solutions";
import Sidebar from "../../../../components/sidebar/Sidebar";
import useGeneralMenuItems from "../../hooks/useGeneralMenuItems";
import useSolutionMenuItems from "../../hooks/useSolutionMenuItems";
import useSolutionMessages from "../../hooks/useSolutionMessages";
import BasicLayout from "./BasicLayout";

interface PrognosLayoutProps {
  children?: React.ReactNode;
}

export default function PrognosLayout({ children }: PrognosLayoutProps) {
  const [{ data: solution }, solutionId] = useSolution();

  useSolutionMessages();

  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems(solutionId);

  const topNavigation = [projectTop, solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}
