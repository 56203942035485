import React from "react";

import { ZodType } from "zod";

export type LocalStorageSetCallback<T> = (change: T | ((prev: T) => T)) => void;

export default function useLocalStorage<T extends object | string | number>(
  key: string,
  defaultValue: T,
  parser?: ZodType
): [T, LocalStorageSetCallback<T>] {
  const [value, setValue] = React.useState<T>(defaultValue);
  React.useEffect(() => {
    const rawValue = localStorage.getItem(key);
    if (typeof rawValue === "string") {
      try {
        setValue(
          parser
            ? parser.parse(JSON.parse(rawValue).value)
            : JSON.parse(rawValue).value
        );
      } catch {
        localStorage.removeItem(key);
      }
    }
  }, []);

  const handleValueChange: LocalStorageSetCallback<T> = (change) => {
    const newValue = typeof change === "function" ? change(value) : change;
    localStorage.setItem(key, JSON.stringify({ value: newValue }));
    setValue(newValue);
  };

  return [value, handleValueChange];
}
