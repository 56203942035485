import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getAnalysisPath } from "./analyzer";

const Settings = lazyWithRetry(
  () => import("../pages/AnalysisSettings/AnalysisSettings")
);

const SETTINGS_PATH = "settings";

export const settingsPath: RouteObject = {
  path: SETTINGS_PATH,
  element: <Settings />,
};

export function getSettingsPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${SETTINGS_PATH}`;
}
