import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  QueueListIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../api/solutions";
import { getCharIcon } from "../../apps/DataStore/pages/DataImportConfig/components/DatasetMenu";
import BasicLayout from "../../apps/PrognosAI/components/layouts/BasicLayout";
import { getTasksPath } from "../../apps/PrognosAI/routes/tasks";
import { getUserSettingsPath } from "../../apps/PrognosAI/routes/userSettings";
import { getSolutionsPath } from "../../routes/solutions";
import { useSuiteApps } from "../Apps";
import Sidebar, { SidebarItem } from "../sidebar/Sidebar";

export default function SolutionLayout(): JSX.Element {
  const [{ data: solution }, solutionId] = useSolution();
  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems();

  const topNavigation = [projectTop, solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout></BasicLayout>
    </Sidebar>
  );
}

function useGeneralMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();

  const topNavigation: SidebarItem[] = [
    {
      key: "projects",
      name: t("Change project"),
      href: getSolutionsPath(),
      icon: QueueListIcon,
      current: false,
    },
  ];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, undefined),
      icon: UserIcon,
      current: location.pathname.startsWith(
        getUserSettingsPath(solutionId, undefined)
      ),
    },
    // {
    //   key: "help",
    //   name: t("Help"),
    //   href: getHelpPath(solutionId, "PrognosAI"),
    //   icon: QuestionMarkCircleIcon,
    //   current: location.pathname.startsWith(
    //     getHelpPath(solutionId, "PrognosAI")
    //   ),
    // },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, undefined),
      icon: RectangleStackIcon,
      current: location.pathname.startsWith(
        getTasksPath(solutionId, undefined)
      ),
    },
  ];

  return [topNavigation, bottomNavigation];
}

function useSolutionMenuItems(): [SidebarItem[], SidebarItem[]] {
  const [, solutionId] = useSolution();
  const apps = useSuiteApps();

  const topItems: SidebarItem[] = apps
    .filter((app) => app.link !== getUserSettingsPath(solutionId, undefined))
    .map((app) => ({
      key: app.link,
      name: app.label,
      href: app.link,
      icon: app.icon ?? getCharIcon(app.label[0]),
      current: location.pathname === app.link,
    }));

  return [topItems, []];
}
