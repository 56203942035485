import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getPrognosPath } from "./prognos";

const InfluencingFactor = lazyWithRetry(
  () => import("../pages/InfluencingFactor/InfluencingFactor")
);
const EntityImportDetail = lazyWithRetry(
  () => import("../pages/EntityImportDetail/EntityImportDetail")
);
const InfluencingFactors = lazyWithRetry(
  () => import("../pages/InfluencingFactors/InfluencingFactors")
);
const General = lazyWithRetry(
  () => import("../pages/InfluencingFactor/sections/General")
);
const Ranges = lazyWithRetry(
  () => import("../pages/InfluencingFactor/sections/Ranges")
);
const Models = lazyWithRetry(
  () => import("../pages/InfluencingFactor/sections/Models")
);
const DataSegments = lazyWithRetry(
  () => import("../pages/InfluencingFactor/sections/DataSegments")
);

const INFLUENCING_FACTORS_PATH = "influencingFactors";
const LIST_PATH = "list";
const IMPORT_PATH = "import";

export const influencingFactorPages = [
  "general",
  "ranges",
  "models",
  "dataSegments",
] as const;
export const InfluencingFactorPageZod = z.enum(influencingFactorPages);
export type InfluencingFactorPage = z.infer<typeof InfluencingFactorPageZod>;

const PageEnum = InfluencingFactorPageZod.Enum;

export const influencingFactorsRoute: RouteObject = {
  path: INFLUENCING_FACTORS_PATH,
  children: [
    { index: true, element: <Navigate to={LIST_PATH} replace /> },
    {
      path: LIST_PATH,
      children: [
        { index: true, element: <InfluencingFactors tab="list" /> },
        {
          path: ":influencingFactorId",
          element: <InfluencingFactor />,
          children: [
            {
              index: true,
              element: <Navigate to={PageEnum.general} replace />,
            },
            { path: PageEnum.general, element: <General /> },
            { path: PageEnum.ranges, element: <Ranges /> },
            { path: PageEnum.models, element: <Models /> },
            { path: PageEnum.dataSegments, element: <DataSegments /> },
          ],
        },
      ],
    },
    {
      path: IMPORT_PATH,
      children: [
        { index: true, element: <InfluencingFactors tab="import" /> },
        {
          path: ":importExportConfigId",
          element: <EntityImportDetail entity="InfluencingFactors" />,
        },
      ],
    },
  ],
};

export function getInfluencingFactorsPath(solutionId: string | number): string {
  return `${getPrognosPath(
    solutionId
  )}/${INFLUENCING_FACTORS_PATH}/${LIST_PATH}`;
}

export function getInfluencingFactorPath(
  solutionId: string | number,
  influencingFactorId: string | number
): string {
  return `${getInfluencingFactorsPath(solutionId)}/${influencingFactorId}`;
}

export function getInfluencingFactorsImportPath(
  solutionId: string | number
): string {
  return `${getPrognosPath(
    solutionId
  )}/${INFLUENCING_FACTORS_PATH}/${IMPORT_PATH}`;
}

export function getInfluencingFactorsImportDetailPath(
  solutionId: string | number,
  importExportConfigId: string | number
): string {
  return `${getInfluencingFactorsImportPath(
    solutionId
  )}/${importExportConfigId}`;
}
