import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useSolution } from "../../../api/solutions";
import SwitchBanner from "../../../components/SwitchBanner";
import {
  AdHocPartition,
  getAdHocPartitionName,
  useAnalysis,
} from "../api/analysis";
import AdHocPartitionSlideover from "./AdHocPartitionSlideover";

interface AdHocPartitionBannerProps {
  adHocPartition: AdHocPartition | null;
  availablePartitions: AdHocPartition[];
  isLoading: boolean;
  className?: string;
  paddings?: boolean;
}

export default function AdHocPartitionBanner(
  props: AdHocPartitionBannerProps
): JSX.Element {
  const { adHocPartition, availablePartitions, isLoading } = props;
  const { className = "", paddings } = props;

  const [{ data: solution }] = useSolution();
  const [{ data: analysis }] = useAnalysis();

  const [openPMSelection, setOpenPMSelection] = React.useState(false);

  const { t } = useTranslation();

  const name = adHocPartition
    ? getAdHocPartitionName(adHocPartition, solution?.partitioners ?? [], t)
    : "";

  const measurement = solution?.measurements.find(
    (m) => m.measurementId === analysis?.measurementId
  );

  const { previousLink, nextLink, count, index } = usePartitionBannerLinks(
    availablePartitions.map((p) => p.adHocPartitionId),
    adHocPartition?.adHocPartitionId ?? null
  );

  return (
    <>
      <SwitchBanner
        previousLink={previousLink}
        nextLink={nextLink}
        count={count}
        index={index}
        isLoading={isLoading}
        className={className}
        paddings={paddings}
      >
        <button
          id="showPartitionMeasurementSlideover"
          title={t("Change the planning area")}
          onClick={() => setOpenPMSelection(true)}
        >
          {name}{" "}
          {measurement && (
            <span className="font-normal text-gray-400">
              [{measurement.name}]
            </span>
          )}
        </button>
      </SwitchBanner>
      {!isLoading && !!adHocPartition && (
        <AdHocPartitionSlideover
          open={openPMSelection}
          availablePartitions={availablePartitions}
          adHocPartitionId={adHocPartition.adHocPartitionId}
          onClose={() => setOpenPMSelection(false)}
        />
      )}
    </>
  );
}

function usePartitionBannerLinks(
  availablePartitions: (number | string)[],
  adHocPartitionId: number | string | null
): {
  previousLink: string;
  nextLink: string;
  index?: number;
  count?: number;
} {
  const [searchParams] = useSearchParams();

  if (adHocPartitionId === null) {
    return { previousLink: "", nextLink: "" };
  }

  if (availablePartitions.length === 0) {
    return { previousLink: "", nextLink: "" };
  }

  if (availablePartitions.length === 1) {
    return { previousLink: "", nextLink: "", index: 1, count: 1 };
  }

  const partitionIndex = availablePartitions.indexOf(adHocPartitionId);

  const prevIndex = partitionIndex - 1;
  const prevPartition = availablePartitions.at(prevIndex);
  let previousLink = "";
  if (prevPartition) {
    searchParams.set("adHocPartitionId", prevPartition.toString());
    previousLink = `?${searchParams.toString()}${window.location.hash}`;
  }

  const nextIndex = (partitionIndex + 1) % availablePartitions.length;
  const nextPartition = availablePartitions.at(nextIndex);
  let nextLink = "";
  if (nextPartition) {
    searchParams.set("adHocPartitionId", nextPartition.toString());
    nextLink = `?${searchParams.toString()}${window.location.hash}`;
  }

  const bannerCount = availablePartitions.length;
  const bannerIndex = partitionIndex + 1;

  return { previousLink, nextLink, count: bannerCount, index: bannerIndex };
}
