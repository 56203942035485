import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

type DefaultOption = {
  key: string | number;
  value: string | number | boolean;
  label?: React.ReactNode;
};

interface RadioProps<Option> {
  id: string;
  label?: string;
  options: readonly Option[];
  value: Omit<Option, "label" | "value"> | null;
  onChange(value: Option): void;
  description?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  ySpace?: string;
  labelYMargin?: string;
}

export default function ListRadio<Option extends DefaultOption>(
  props: RadioProps<Option>
): JSX.Element {
  const { id, label, options, value, onChange } = props;
  const { description, disabled, size = "medium" } = props;
  const { ySpace = "space-y-4", labelYMargin } = props;

  const { t } = useTranslation();

  return (
    <div className="my-2">
      {label && (
        <label
          className={classNames(
            "font-medium text-gray-700",
            size === "small" ? "text-sm" : "text-base"
          )}
        >
          {label}
        </label>
      )}
      {description && (
        <p className="text-sm leading-5 text-gray-500">{description}</p>
      )}
      <fieldset
        id={id}
        className={
          label || description
            ? size === "small"
              ? `${labelYMargin ?? "mt-3"} mb-2`
              : (labelYMargin ?? "mt-4")
            : ""
        }
      >
        <legend className="sr-only">
          {label} {t("option")}
        </legend>
        <div className={ySpace}>
          {options.map((option) => (
            <div key={option.key} className="flex items-center cursor-pointer">
              <input
                id={`${id}-radio-${option.key}`}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                checked={option.key === value?.key}
                onChange={() => onChange(option)}
                disabled={disabled}
              />
              <label
                htmlFor={`${id}-radio-${option.key}`}
                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
              >
                {option.label ?? option.key}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
