import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { dataImportConfigQuery } from "../../../../PrognosAI/api/datasets";
import {
  activeDataAnalysisTasksQuery,
  activeDataImportConfigTasksQuery,
  activeDataImportTasksQuery,
} from "../../../../PrognosAI/api/tasks";
import { DataImportConfigDetail } from "../../../../PrognosAI/models/dataImportConfig";
import { Message } from "../../../../PrognosAI/models/message";
import {
  DataImportConfigTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../../../../PrognosAI/models/task";

export const useDatasetMessagesCallback = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parser = DataImportConfigTaskZod.safeParse(msg.eventArgs);
      if (!parser.success) {
        return;
      }

      const task = parser.data;

      const solutionId = task.solutionId.toString();
      const dataImportConfigId = task.dataImportConfigId.toString();

      const tasksToInvalidate = [
        activeDataAnalysisTasksQuery(solutionId),
        activeDataImportTasksQuery(solutionId),
        activeDataImportConfigTasksQuery(solutionId, dataImportConfigId),
      ];

      for (const query of tasksToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        // import changes so many things that it's easier to invalidate everything
        queryClient.invalidateQueries();
      }

      if (task.status === "Failed") {
        return toast.error(
          task.taskName === "DataImportAnalysis"
            ? t("The dataset failed to be analyzed. Please try again.")
            : t("The dataset failed to be imported. Please try again.")
        );
      }
      if (task.status === "Canceled") {
        return toast.success(
          task.taskName === "DataImportAnalysis"
            ? t("The dataset analysis was canceled successfully.")
            : t("The dataset import was canceled successfully.")
        );
      }
      if (task.status === "Finished") {
        queryClient.setQueryData<DataImportConfigDetail>(
          dataImportConfigQuery(dataImportConfigId).queryKey,
          (prev) =>
            prev
              ? {
                  ...prev,
                  analyzed:
                    task.taskName === "DataImportAnalysis"
                      ? new Date()
                      : prev.analyzed,
                  imported:
                    task.taskName === "DataImport" ? new Date() : prev.imported,
                }
              : prev
        );
        return toast.success(
          task.taskName === "DataImportAnalysis"
            ? t("The dataset analysis was finished successfully.")
            : t("The dataset import was finished successfully.")
        );
      }
    },
    [queryClient, t]
  );

  return callback;
};
