import React from "react";
import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

import FilterComponent from "../../apps/PrognosAI/components/filter/FilterComponent";
import {
  FilterInstance,
  FilterInterface,
} from "../../apps/PrognosAI/components/filter/utils";
import TableCell, {
  TABLE_CELL_FONT,
  TABLE_CELL_LEFT_PADDING,
} from "../../apps/PrognosAI/components/table/TableCell";
import TableHead from "../../apps/PrognosAI/components/table/TableHead";
import TableRow from "../../apps/PrognosAI/components/table/TableRow";
import { SortSetting } from "../../models/primitives";
import Checkbox from "../form/Checkbox";
import { Column, DataTableGenericHeadProps } from "./types";
import {
  filterInstanceToFilterValues,
  filterValuesToFilterInstance,
} from "./utils";

interface DataTableHeadProps<C extends Column[]>
  extends DataTableGenericHeadProps<C> {
  tableId?: string | number;
  hasActionsColumn?: boolean;
  showFilters?: boolean;
  checkbox?: {
    checked: boolean;
    variant: "primary" | "secondary";
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
}

export default function DataTableHead<C extends Column[]>(
  props: DataTableHeadProps<C>
): JSX.Element {
  const { tableId, columns } = props;
  const { hasActionsColumn = false, sort = [] } = props;
  const { showFilters = false, filter = [], headCellClassName } = props;
  const { onSort, onFilter } = props;
  const { checkbox } = props;

  const { t } = useTranslation();

  const filterRef = React.useRef(null);

  const filterInterfaces: FilterInterface[] = columns
    .filter((col) => col.filterable)
    .map((col) => ({
      id: col.key,
      columnKey: col.apiKey ?? col.key,
      type: col.type,
      options: getFilterSelectOptions(col, t),
    }));

  const filterInstances: FilterInstance[] = filterInterfaces
    .map((int) =>
      filterValuesToFilterInstance(
        filter.filter((f) => f[0] === int.columnKey),
        int
      )
    )
    .filter(
      (inst: FilterInstance | undefined): inst is FilterInstance => !!inst
    );

  const handleFilterChange = (filterI: FilterInstance) => {
    if (!onFilter) {
      return;
    }
    const newFilter = [
      ...filter.filter((f) => f[0] !== filterI.columnKey),
      ...filterInstanceToFilterValues(filterI),
    ];
    onFilter(newFilter);
  };

  const handleColumnSortChange = (
    key: string,
    sortable: boolean,
    currentDirection?: SortSetting[1]
  ) => {
    if (!sortable || !onSort) {
      return;
    }
    onSort([[key, currentDirection === "asc" ? "desc" : "asc"]]);
  };

  const showColumnActions = columns.some((c) => !!c.columnAction && !!c.label);

  return (
    <TableHead>
      <TableRow noHover>
        {!!checkbox && (
          <TableCell as="th">
            <Checkbox {...checkbox} />
          </TableCell>
        )}
        {columns.map((col) => {
          const sortable = !!(col.sortable && onSort);
          const colSortIdx = sort.findIndex(
            (s) => s[0] === (col.apiKey ?? col.key)
          );
          const colSort = colSortIdx !== -1 ? sort.at(colSortIdx) : undefined;

          return (
            <TableCell
              key={`${col.key}-${col.type}-${col.label}`}
              as="th"
              className={classNames(
                col.labelAlign
                  ? col.labelAlign
                  : (col.type === "number" && !col.align) ||
                      col.align === "right"
                    ? "text-right"
                    : "",
                //col.align === "center" ? "text-center" : "",
                sortable ? "cursor-pointer" : "",
                showFilters ? TABLE_CELL_LEFT_PADDING : "",
                headCellClassName ?? "",
                !col.label && col.columnAction ? "text-center" : ""
              )}
              noPadding={showFilters}
              onClick={() =>
                handleColumnSortChange(
                  col.apiKey ?? col.key,
                  sortable,
                  colSort?.[1]
                )
              }
            >
              {col.label ?? col.columnAction}{" "}
              {colSort && (
                <span className="text-gray-400">
                  {colSort[1] === "asc" ? (
                    <ArrowUpIcon className="ml-1 w-4 h-4" />
                  ) : (
                    <ArrowDownIcon className="ml-1 w-4 h-4" />
                  )}
                  {sort.length > 1 && (
                    <span className="font-normal">{colSortIdx + 1}</span>
                  )}
                </span>
              )}
            </TableCell>
          );
        })}
        {hasActionsColumn && (
          <TableCell as="th">
            <span className="sr-only">{t("Actions")}</span>
          </TableCell>
        )}
      </TableRow>
      {showFilters && (
        <TableRow noHover>
          {!!checkbox && <TableCell as="td"></TableCell>}
          {columns.map((col) => {
            const filterInterface = filterInterfaces.find(
              (int) => int.columnKey === (col.apiKey ?? col.key)
            );

            if (!filterInterface) {
              return (
                <TableCell key={col.apiKey ?? col.key} as="td"></TableCell>
              );
            }

            return (
              <TableCell
                key={col.key}
                as="td"
                className={`pt-0 pb-3 ${TABLE_CELL_LEFT_PADDING} ${TABLE_CELL_FONT}`}
                noPadding
              >
                <FilterComponent
                  id={`${tableId ?? "dataTable"}-filterComponent-${col.key}`}
                  cleanable={col.type.startsWith("date")}
                  maxWidth={col.type.startsWith("date") ? 160 : undefined}
                  refs={filterRef}
                  filterInterface={filterInterface}
                  filters={filterInstances}
                  onChange={handleFilterChange}
                />
              </TableCell>
            );
          })}
        </TableRow>
      )}
      {showColumnActions && (
        <TableRow noHover>
          {!!checkbox && <TableCell as="td"></TableCell>}
          {columns.map((col) => {
            if (!col.columnAction || !col.label) {
              return (
                <TableCell key={col.apiKey ?? col.key} as="td"></TableCell>
              );
            }

            return (
              <TableCell
                key={col.key}
                as="td"
                className="text-center pt-0 pb-3"
              >
                {col.columnAction}
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </TableHead>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const getFilterSelectOptions = (column: Column, t: TFunction) => {
  const output = [{ key: "", label: t("All") }];

  const { options } = column;

  if (column.type === "boolean") {
    return [
      ...output,
      { key: "True", label: options?.yesLabel ?? t("Yes") },
      { key: "False", label: options?.noLabel ?? t("No") },
    ];
  }

  if (column.type === "enum") {
    return [...output, ...(options?.enumOptions ?? [])];
  }

  return undefined;
};
