import React from "react";
import { Outlet } from "react-router-dom";

import Version from "../sidebar/Version";

export default function FirstLevelLayout(): JSX.Element {
  return (
    <div className="p-4">
      <div className="max-w-7xl m-auto">
        <Outlet />
        <div className="fixed bottom-0 left-0 bg-slate-100">
          <Version />
        </div>
      </div>
    </div>
  );
}
