import React from "react";
import { FiChevronRight } from "react-icons/fi";

interface BreadcrumbsProps {
  children?: JSX.Element | JSX.Element[];
}

export default function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
  let { children } = props;

  if (children) {
    children = Array.isArray(children)
      ? children
      : ([children] as JSX.Element[]);
  } else {
    children = [];
  }

  return (
    <nav className="text-xs mb-3 px-6 flex flex-wrap items-center">
      {children.map((child, index) => {
        return (
          <React.Fragment key={index}>
            {!!index && <FiChevronRight className="text-slate-400 mx-1" />}
            {child}
          </React.Fragment>
        );
      })}
    </nav>
  );
}
