import React from "react";

import classNames from "classnames";

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: "primary" | "secondary";
}

export default function Checkbox(props: CheckboxProps) {
  const { variant = "primary", className = "", ...rest } = props;

  return (
    <input
      {...rest}
      type="checkbox"
      className={classNames(
        "form-checkbox h-4 w-4 rounded border-gray-300",
        variant === "primary" ? "text-blue-600" : "text-gray-400",
        "focus:ring-blue-500 cursor-pointer",
        "disabled:cursor-default disabled:bg-gray-100 disabled:checked:bg-gray-300",
        className
      )}
    />
  );
}
