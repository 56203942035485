import React from "react";
import { RouteObject } from "react-router";

import { SuiteApp, getAppPathPrefix } from "../../../routes";
import { getSolutionPath, getSolutionsPath } from "../../../routes/solutions";
import lazyWithRetry from "../../../services/lazyWithRetry";

const Help = lazyWithRetry(() => import("../pages/Help/Help"));

const HELP_PATH = "help";

export const helpRoute: RouteObject = { path: HELP_PATH, element: <Help /> };

export function getHelpPath(
  solutionId: string | number | undefined,
  app: SuiteApp | undefined
): string {
  if (!solutionId) {
    return `${getSolutionsPath()}/${HELP_PATH}`;
  }

  if (!app) {
    return `${getSolutionPath(solutionId)}/${HELP_PATH}`;
  }

  return `${getAppPathPrefix(solutionId, app)}/${HELP_PATH}`;
}
