import React from "react";

import DisabledOverlay from "../apps/DataStore/pages/Import/components/DisabledOverlay";
import Card from "./Card";
import HeroBreadcrumbs, { HeroPath } from "./HeroBreadcrumbs";

interface HeroProps {
  children?: React.ReactNode;
  path?: HeroPath;
  hideRootPath?: boolean;
  actions?: React.ReactNode;
  cardClassName?: string;
  disabled?: boolean;
}

export default function Hero(props: HeroProps): JSX.Element {
  const { children, path = [], actions } = props;
  const { cardClassName = "", disabled = false, hideRootPath = false } = props;
  return (
    <>
      <HeroBreadcrumbs path={path} hideRootPath={hideRootPath} />
      <div className={disabled ? "relative" : ""}>
        <Card
          className={`mb-4 flex justify-between items-center md:h-[4.5rem] space-x-1 ${cardClassName}`}
        >
          <h2 className="flex-grow">{children}</h2>
          {actions && (
            <div className="space-x-1 flex items-center">{actions}</div>
          )}
        </Card>
        {disabled && <DisabledOverlay />}
      </div>
    </>
  );
}
