import React from "react";
import { Link } from "react-router-dom";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";

interface BacklinkProps {
  to: string;
  children: React.ReactNode;
  hideChevron?: boolean;
}

export default function Backlink(props: BacklinkProps): JSX.Element {
  const { to, children, hideChevron = false } = props;

  return (
    <Link to={to} className="underline-offset-2">
      {!hideChevron && <ChevronLeftIcon className="w-4 h-4 -ml-1" />}
      {children}
    </Link>
  );
}
