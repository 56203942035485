import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getPrognosPath } from "./prognos";

const Run = lazyWithRetry(() => import("../pages/Run/Run"));
const Runs = lazyWithRetry(() => import("../pages/Runs/Runs"));
const General = lazyWithRetry(() => import("../pages/Run/sections/General"));
const Task = lazyWithRetry(() => import("../pages/Run/sections/Task"));
const DataSelection = lazyWithRetry(
  () => import("../pages/Run/sections/DataSelectionSettings")
);
const Outliers = lazyWithRetry(() => import("../pages/Run/sections/Outliers"));
const Preprocessing = lazyWithRetry(
  () => import("../pages/Run/sections/Preprocessing")
);
const Postprocessing = lazyWithRetry(
  () => import("../pages/Run/sections/Postprocessing")
);
const IntradayProfiles = lazyWithRetry(
  () => import("../pages/Run/sections/IntradayProfiles")
);
const Advanced = lazyWithRetry(() => import("../pages/Run/sections/Advanced"));
const Models = lazyWithRetry(() => import("../pages/Run/sections/Models"));
const FixedModels = lazyWithRetry(
  () => import("../pages/Run/sections/FixedModels")
);

const RUNS_PATH = "runs";

const runConfigPages = [
  "general",
  "task",
  "data",
  "outliers",
  "preprocessing",
  "postprocessing",
  "intradayProfiles",
  "advanced",
  "models",
  "fixedModels",
] as const;
export const RunConfigPageZod = z.enum(runConfigPages);
export type RunConfigPage = z.infer<typeof RunConfigPageZod>;

const PageEnum = RunConfigPageZod.Enum;

export const runConfigsRoute: RouteObject = {
  path: RUNS_PATH,
  children: [
    { index: true, element: <Runs /> },
    {
      path: ":runConfigId",
      element: <Run />,
      children: [
        { index: true, element: <Navigate to={PageEnum.general} replace /> },
        { path: PageEnum.general, element: <General /> },
        { path: PageEnum.task, element: <Task /> },
        { path: PageEnum.data, element: <DataSelection /> },
        { path: PageEnum.outliers, element: <Outliers /> },
        { path: PageEnum.preprocessing, element: <Preprocessing /> },
        { path: PageEnum.postprocessing, element: <Postprocessing /> },
        { path: PageEnum.intradayProfiles, element: <IntradayProfiles /> },
        { path: PageEnum.advanced, element: <Advanced /> },
        { path: PageEnum.models, element: <Models /> },
        { path: PageEnum.fixedModels, element: <FixedModels /> },
      ],
    },
  ],
};

export function getRunsPath(solutionId: string | number): string {
  return `${getPrognosPath(solutionId)}/${RUNS_PATH}`;
}

export function getRunDetailPath(
  solutionId: string | number,
  runConfigId: string | number
): string {
  return `${getRunsPath(solutionId)}/${runConfigId}`;
}
