import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { csrfTokenQuery } from "../..//api/user";
import env from "../../services/env";

type LogoutButtonProps = {
  id: string;
  type: "submit";
  disabled: boolean;
};

interface LogoutFormProps {
  id?: string;
  children?: (props: LogoutButtonProps) => JSX.Element;
}

export default function LogoutForm(props: LogoutFormProps): JSX.Element {
  const { id, children } = props;

  const { data: csrf } = useQuery(csrfTokenQuery());

  const { t } = useTranslation();

  const btnProps: LogoutButtonProps = {
    id: `${id ? id + "-" : ""}signOutButton`,
    type: "submit",
    disabled: !csrf?.csrftoken,
  };

  const button = children ? (
    children(btnProps)
  ) : (
    <button
      {...btnProps}
      className="text-xs font-medium text-gray-500 hover:text-gray-700 hover:underline"
    >
      {t("Sign out")}
    </button>
  );

  return (
    <form
      className="text-xs"
      action={`${env.API_URL}/accounts/logout/`}
      method="POST"
    >
      <input
        type="hidden"
        name="csrfmiddlewaretoken"
        value={csrf?.csrftoken ?? ""}
      />
      {button}
    </form>
  );
}
