import React from "react";
import { CalendarContainer } from "react-datepicker";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { TFunction } from "i18next";

import { FILTER_EL_STYLE } from ".";
import FormattedDatePicker from "../../../../components/FormattedDatePicker";
import Button from "../../../../components/buttons/Button";

interface FilterRangePickerProps {
  style?: React.StyleHTMLAttributes<HTMLInputElement>;
  value: string;
  onChange(value: string): void;
}

export default function FilterRangePicker(props: FilterRangePickerProps) {
  const { value, onChange } = props;
  const handleValueChange = ([from, to]: [Date, Date]) =>
    onChange(getStringFromDates(from, to));

  const CustomContainer = (props: {
    className: string;
    children: React.ReactNode;
  }) => {
    const { className, children } = props;

    return (
      <div className="flex">
        <RangeButtonPanel onClick={handleValueChange} />
        <CalendarContainer className={className}>{children}</CalendarContainer>
      </div>
    );
  };

  const [start, end] = getDatesFromString(value);

  return (
    <FormattedDatePicker
      selectsRange
      selected={start ?? undefined}
      startDate={start ?? undefined}
      endDate={end ?? undefined}
      // @ts-expect-error weird TS behavior on the DatePicker["props"] type
      onChange={handleValueChange}
      // for some reason the customInput doesn't work with a separate component
      // even using proper ref forwarding
      customInput={
        <input
          type="text"
          className={classNames("grow ml-0.5 pl-2", FILTER_EL_STYLE)}
          style={{ lineHeight: "28px" }}
        />
      }
      calendarContainer={CustomContainer}
    />
  );
}

interface RangeButtonPanelProps {
  onClick([from, to]: [Date, Date]): void;
}

function RangeButtonPanel(props: RangeButtonPanelProps): JSX.Element {
  const { t } = useTranslation();
  const { onClick } = props;

  const rangeButtons = getRangeButtons(t);

  return (
    <div className="flex flex-col justify-end">
      {rangeButtons.map((button) => (
        <Button
          key={button.id}
          id={button.id}
          size="small"
          variant="white"
          onClick={() => onClick([button.from, button.to])}
          className="h-8 mb-1 mr-2"
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
}

const getRangeButtons = (t: TFunction) => {
  const today = new Date();
  const mon = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - ((today.getDay() + 6) % 7)
  );
  const sun = new Date(mon.getFullYear(), mon.getMonth(), mon.getDate() + 6);
  const end = [23, 59, 59, 999];

  return [
    {
      id: "thisWeek",
      label: t("This week"),
      from: mon,
      to: new Date(sun.getFullYear(), sun.getMonth(), sun.getDate(), ...end),
    },
    {
      id: "lastWeek",
      label: t("Last week"),
      from: new Date(mon.getFullYear(), mon.getMonth(), mon.getDate() - 7),
      to: new Date(
        sun.getFullYear(),
        sun.getMonth(),
        sun.getDate() - 7,
        ...end
      ),
    },
    {
      id: "thisMonth",
      label: t("This month"),
      from: new Date(today.getFullYear(), today.getMonth(), 1),
      to: new Date(today.getFullYear(), today.getMonth() + 1, 0, ...end),
    },
    {
      id: "lastMonth",
      label: t("Last month"),
      from: new Date(today.getFullYear(), today.getMonth() - 1, 1),
      to: new Date(today.getFullYear(), today.getMonth(), 0, ...end),
    },
    {
      id: "thisYear",
      label: t("This year"),
      from: new Date(today.getFullYear(), 0, 1),
      to: new Date(today.getFullYear(), 11, 31, ...end),
    },
    {
      id: "lastYear",
      label: t("Last year"),
      from: new Date(today.getFullYear() - 1, 0, 1),
      to: new Date(today.getFullYear() - 1, 11, 31, ...end),
    },
  ];
};

export const getDatesFromString = (
  value: string
): [Date | null, Date | null] => {
  const start = (value ?? "").split(" - ")[0] ?? null;
  let end: string | null = (value ?? "").split(" - ")[1] ?? null;
  if (end === "undefined") {
    end = null;
  }

  return [start ? new Date(start) : null, end ? new Date(end) : null];
};

const getStringFromDates = (from: Date | null, to: Date | null): string => {
  if (!from && !to) {
    return "";
  }
  if (!to) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return from!.toISOString();
  }
  if (!from) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return to!.toISOString();
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return `${from!.toISOString()} - ${to!.toISOString()}`;
};
