import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getDemandPath } from "./demand";

const Demand = lazyWithRetry(() => import("../pages/Demand/Demand"));

const BUSINESS_MAP_PATH = "businessMap";

export const businessMapRoute: RouteObject = {
  path: BUSINESS_MAP_PATH,
  element: <Demand />,
};

export function getBusinessMapPath(solutionId: string | number): string {
  return `${getDemandPath(solutionId)}/${BUSINESS_MAP_PATH}`;
}
