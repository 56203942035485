import { getSolutionPath } from "../../../routes/solutions";

export const ANALYZER_PATH = "analyzer";

export function getAnalyzerPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${ANALYZER_PATH}`;
}

export function getAnalysisPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalyzerPath(solutionId)}/${analysisId}`;
}
