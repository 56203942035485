import React from "react";
import { useTranslation } from "react-i18next";

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { BUTTON_ICON_MEDIUM, BUTTON_ICON_SMALL } from "../buttons";

const SMALL =
  "text-xs py-1.5 pl-2 pr-8 focus:border-gray-300 focus:ring-offset-1 focus:ring-blue-300";
const MEDIUM = "text-sm py-2 pl-3 pr-10";

const DIRECTION_DOWN = "";
const DIRECTION_UP = "-top-2 transform -translate-y-full";

export type SelectOption = {
  [key: string]: string | number | boolean | null;
};

interface SelectProps<Option> {
  id: string;
  label?: string;
  options: readonly Option[];
  value: Option | null;
  onChange(value: Option): void;
  disabled?: boolean;
  keyProp?: keyof Option & string;
  labelProp?: keyof Option & string;
  placeholderLabel?: string;
  size?: "small" | "medium";
  direction?: "up" | "down";
  display?: "block" | "inline-block";
  className?: string;
  labelClassName?: string;
  autoFocus?: boolean;
  info?: string;
  error?: string | boolean;
  allowNone?: boolean;
}

// TODO remove copypaste code from Select & MultiSelect
export default function Select<Option extends SelectOption>(
  props: SelectProps<Option>
): JSX.Element {
  const { label, options, value, id, display, info, error } = props;
  const { onChange } = props;
  const { keyProp = "key", labelProp = "label" } = props;
  const { disabled = false, autoFocus = false, allowNone = false } = props;
  const { size = "medium", labelClassName = "", direction = "down" } = props;
  const { className = "" } = props;

  const { t } = useTranslation();
  const { placeholderLabel = t("select") } = props;

  const getOptionLabel = (
    option: Option | null
  ): string | number | boolean | null => {
    if (option === null) {
      return null;
    }
    return option[labelProp] ?? option[keyProp];
  };

  const usedOptions = allowNone
    ? [
        { [keyProp]: null, [labelProp]: `-- ${placeholderLabel} --` } as Option,
        ...options,
      ]
    : options;

  return (
    <Listbox by={keyProp} value={value} onChange={onChange} disabled={disabled}>
      {label && (
        <Label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </Label>
      )}
      <div id={id} className={classNames("relative", display ?? "")}>
        <ListboxButton
          // probably a library bug: the custom ids don't work
          //id={`${id}-button`}
          className={classNames(
            "relative w-full cursor-pointer rounded border border-gray-300 bg-white disabled:bg-gray-100 transition-colors duration-300 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed",
            size === "small" ? SMALL : MEDIUM,
            error
              ? "border-red-400 focus:border-red-500 focus:ring-red-500"
              : "",
            allowNone && value === null ? "text-gray-400" : "",
            className
          )}
          autoFocus={autoFocus}
        >
          <span className={classNames("block truncate", labelClassName)}>
            {getOptionLabel(value) ?? `-- ${placeholderLabel} --`}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className={classNames(
                "text-gray-400",
                size === "small" ? BUTTON_ICON_SMALL : BUTTON_ICON_MEDIUM
              )}
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
            direction === "up" ? DIRECTION_UP : DIRECTION_DOWN
          }`}
        >
          {usedOptions.map((option, index) => (
            <ListboxOption
              key={option[keyProp]?.toString() ?? `none-${index}`}
              // probably a library bug: the custom ids don't work
              //id={`${id}-option-${option[keyProp]?.toString() ?? `none-${index}`}`}
              className={({ focus, selected }) =>
                classNames(
                  focus ? "text-white bg-blue-600" : "text-gray-900",
                  "relative cursor-pointer select-none",
                  size === "small" ? SMALL : MEDIUM,
                  selected ? "" : size === "small" ? "pr-2" : "pr-3"
                )
              }
              value={option}
            >
              {({ focus, selected }) => (
                <>
                  <span
                    id={`${id ?? "select"}-option-${option[keyProp]}`}
                    className={classNames(
                      selected ? "font-semibold" : "font-normal",
                      "block truncate"
                    )}
                  >
                    {getOptionLabel(option)}
                  </span>
                  {selected ? (
                    <span
                      className={classNames(
                        focus ? "text-white" : "text-blue-600",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      )}
                    >
                      <CheckIcon
                        className={
                          size === "small"
                            ? BUTTON_ICON_SMALL
                            : BUTTON_ICON_MEDIUM
                        }
                        aria-hidden="true"
                      />
                    </span>
                  ) : null}
                </>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
      {typeof error === "string" && !!error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
      {info && !error && <p className="mt-2 text-sm text-gray-500">{info}</p>}
    </Listbox>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
