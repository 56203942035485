import React from "react";
import { useTranslation } from "react-i18next";

import { useSolution } from "../../../api/solutions";
import AutosaveIndicator from "../../../components/AutosaveIndicator";
import Card from "../../../components/Card";
import SkeletonCard from "../../../components/skeletons/SkeletonCard";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import useAutosave from "../../PrognosAI/hooks/useAutosave";
import { useAnalysis, useEditAnalysis } from "../api/analysis";
import DataSettings from "./DataSettings";

interface SettingsBarProps {
  pageTitle: string | null;
  children: React.ReactNode;
}

export default function SettingsBar(props: SettingsBarProps): JSX.Element {
  const { pageTitle, children } = props;
  const { t } = useTranslation();

  const [, solutionId] = useSolution();
  const [{ data: origAnalysis }, analysisId] = useAnalysis();

  const editAnalysis = useEditAnalysis(solutionId, analysisId);
  const autosave = useAutosave(origAnalysis, editAnalysis.mutateAsync, {
    delay: 1000,
  });
  const { entity: analysis, handleChange, status } = autosave;

  // TODO error handling

  return (
    <div
      className="w-96 space-y-4 overflow-y-auto rounded-md"
      style={{ maxHeight: "calc(100vh - 60px)" }}
    >
      <Card>
        {!!analysis && <h1 className="text-xl font-bold">{analysis?.name}</h1>}
        {!analysis && (
          <div className="animate-pulse bg-gray-200 w-full rounded h-7 py-2 pl-3 pr-10"></div>
        )}
        <h2 className="text-base font-medium text-gray-400">{pageTitle}</h2>
        <div className="space-y-4 py-2 empty:hidden">{children}</div>
      </Card>
      {!!analysis && (
        <Card className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-base font-semibold">{t("Data selection")}</h3>
            <p className="w-5 h-5">
              <AutosaveIndicator status={status} noStyle />
            </p>
          </div>
          <DataSettings analysis={analysis} onChange={handleChange} contained />
        </Card>
      )}
      {!analysis && (
        <SkeletonWrapper spinnerSize={24}>
          <SkeletonCard style={{ height: 300 }} />
        </SkeletonWrapper>
      )}
    </div>
  );
}
