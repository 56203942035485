import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getAnalysisPath } from "./analyzer";

const HeatmapPage = lazyWithRetry(
  () => import("../pages/HeatmapPage/HeatmapPage")
);

const HEATMAP_PAGE_PATH = "heatmap";

export const heatmapPageRoute: RouteObject = {
  path: HEATMAP_PAGE_PATH,
  element: <HeatmapPage />,
};

export function getHeatmapPagePath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${HEATMAP_PAGE_PATH}`;
}
