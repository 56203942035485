import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getPrognosPath } from "./prognos";

const Outlier = lazyWithRetry(() => import("../pages/Outlier/Outlier"));
const Outliers = lazyWithRetry(() => import("../pages/Outliers/Outliers"));
const General = lazyWithRetry(
  () => import("../pages/Outlier/sections/General")
);
const Ranges = lazyWithRetry(() => import("../pages/Outlier/sections/Ranges"));
const RunConfigs = lazyWithRetry(
  () => import("../pages/Outlier/sections/RunConfigs")
);
const DataSegments = lazyWithRetry(
  () => import("../pages/Outlier/sections/DataSegments")
);

const OUTLIERS_PATH = "outliers";

export const outlierPages = [
  "general",
  "ranges",
  "runConfigs",
  "dataSegments",
] as const;
export const OutlierPageZod = z.enum(outlierPages);
export type OutlierPage = z.infer<typeof OutlierPageZod>;

const PageEnum = OutlierPageZod.Enum;

export const outliersRoute: RouteObject = {
  path: OUTLIERS_PATH,
  children: [
    { index: true, element: <Outliers /> },
    {
      path: ":outlierId",
      element: <Outlier />,
      children: [
        { index: true, element: <Navigate to={PageEnum.general} replace /> },
        { path: PageEnum.general, element: <General /> },
        { path: PageEnum.ranges, element: <Ranges /> },
        { path: PageEnum.runConfigs, element: <RunConfigs /> },
        { path: PageEnum.dataSegments, element: <DataSegments /> },
      ],
    },
  ],
};

export function getOutliersPath(solutionId: string | number): string {
  return `${getPrognosPath(solutionId)}/${OUTLIERS_PATH}`;
}

export function getOutlierDetailPath(
  solutionId: string | number,
  outlierId: string | number
): string {
  return `${getOutliersPath(solutionId)}/${outlierId}`;
}
