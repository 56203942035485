import { Partition } from "../models/partition";
import { Partitioner } from "../models/partitioner";

export const getPartitionLeaves = (
  partitionId: number,
  partitions: Partition[]
): Partition[] => {
  const children = partitions.filter(
    (node) => node.parentPartitionId === partitionId
  );

  // This node is a leaf node
  if (children.length === 0) {
    return [];
  }

  const result: Partition[] = [];

  // This node has children, so we need to check its children for leaf nodes
  for (const child of children) {
    const childLeaves = getPartitionLeaves(child.partitionId, partitions);
    if (childLeaves.length === 0) {
      result.push(child);
    } else {
      result.push(...childLeaves);
    }
  }

  return result;
};

export const getPartionAncestorsIds = (
  partitionId: number,
  partitions: Partition[]
): number[] => {
  const partition = partitions.find((p) => p.partitionId === partitionId);
  if (!partition || !partition.parentPartitionId) {
    return [];
  }

  return [
    partition.parentPartitionId,
    ...getPartionAncestorsIds(partition.parentPartitionId, partitions),
  ];
};

function getPartitionAncestors(
  partitions: Partition[],
  partition: Partition
): Partition[] {
  const result = [];
  let currPartition: Partition | undefined = partition;
  while (currPartition?.parentPartitionId) {
    currPartition = partitions.find(
      (p) => p.partitionId === currPartition?.parentPartitionId
    );
    if (currPartition && currPartition.parentPartitionId) {
      result.unshift(currPartition);
    }
  }

  return result;
}

export function getFullPartitionName(
  partitions: Partition[],
  partition: Partition
): string {
  const ancestors = getPartitionAncestors(partitions, partition);
  return [...ancestors, partition].map((p) => p.name).join(", ");
}

export function getLeafPartitions(
  partitions: Partition[],
  partitioners: Partitioner[]
): Partition[] {
  const maxOrder = Math.max(0, ...partitioners.map((p) => p.order));
  const leafPartitioner = partitioners.find((p) => p.order === maxOrder);
  const leafPartitions = partitions.filter(
    (p) =>
      !!leafPartitioner && p.partitionerId === leafPartitioner.partitionerId
  );

  return leafPartitions;
}

export function getLeafPartitionsCount(
  partitions: Partition[],
  partitioners: Partitioner[]
): number {
  const leafPartitions = getLeafPartitions(partitions, partitioners);
  return leafPartitions.length;
}
