import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import api, { EndpointOptions } from "../../../api";
import { getQueryString } from "../../../api";
import {
  ModelDeletionErrorZod,
  ModelRunDependency,
} from "../models/dependency";
import {
  Model,
  ModelDetail,
  ModelDetailZod,
  ModelUsedIdentifiers,
  ModelUsedIdentifiersZod,
  ModelZod,
} from "../models/model";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import { getModelDetailPath, getModelsPath } from "../routes/models";
import { getFactorsQueryPrefix } from "./influencingFactors";

export const MODELS_API = "/Prognos/Models";

async function getModels(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Model[]>> {
  const query = getQueryString(options);
  const modelsQ = paginatedResponse(ModelZod.array()).parse(
    (await api.get(`/Prognos/Solutions/${solutionId}/Models?${query}`)).data
  );
  return modelsQ;
}

function getModelsQueryPrefix(solutionId: string | number) {
  return { queryKey: ["models", `${solutionId}`] };
}

export const modelsQuery = (
  solutionId: string | number,
  options?: EndpointOptions
) => ({
  queryKey: [
    ...getModelsQueryPrefix(solutionId).queryKey,
    "list",
    ...(options ? [options] : []),
  ],
  queryFn: () => getModels(solutionId, options),
});

async function getModel(modelId: string | number): Promise<ModelDetail> {
  return ModelDetailZod.parse((await api.get(`${MODELS_API}/${modelId}`)).data);
}

export const modelQuery = (modelId: string | number) => ({
  queryKey: ["model", modelId.toString()],
  queryFn: () => getModel(modelId),
});

async function createModel(
  solutionId: string,
  model: ModelDetail
): Promise<ModelDetail> {
  return ModelDetailZod.parse(
    (await api.post(`/Prognos/Solutions/${solutionId}/Models`, model)).data
  );
}

export const useCreateModel = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (model: ModelDetail) => createModel(solutionId, model),
    onSuccess: (data) => {
      toast.success(t("Model saved successfully"));
      queryClient.invalidateQueries(getModelsQueryPrefix(solutionId));
      navigate(getModelDetailPath(solutionId, data.modelId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function updateModel(modelId: string, patch: Partial<ModelDetail>) {
  return ModelDetailZod.parse(
    (await api.patch(`${MODELS_API}/${modelId}`, patch)).data
  );
}

export const useEditModel = (solutionId: string, modelId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<ModelDetail>) => updateModel(modelId, patch),
    onSuccess: (newModel) => {
      queryClient.setQueryData(["model", modelId], newModel);
      queryClient.invalidateQueries(getModelsQueryPrefix(solutionId));
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function copyModel(modelId: string | number) {
  return ModelDetailZod.parse(
    (await api.post(`${MODELS_API}/${modelId}/Copy`)).data
  );
}

export const useCopyModel = (solutionId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: copyModel,
    onSuccess: (model) => {
      queryClient.invalidateQueries(getModelsQueryPrefix(solutionId));
      queryClient.invalidateQueries(getFactorsQueryPrefix(solutionId));
      queryClient.invalidateQueries({ queryKey: ["influencingFactor"] });
      navigate(getModelDetailPath(solutionId, model.modelId));
    },
    onError: () => {
      toast.error(t("An error occurred while copying. Please try again."));
    },
  });
};

async function deleteModel(modelId: string, force = false) {
  return api.delete(`${MODELS_API}/${modelId}${force ? "?force=true" : ""}`);
}

export const useDeleteModel = (
  solutionId: string,
  onConfirmation?: (model: Model, entries: ModelRunDependency[]) => void,
  redirect = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({ model, force = false }: { model: Model; force?: boolean }) =>
      deleteModel(model.modelId.toString(), force),
    onSuccess: (_, { model: { modelId } }) => {
      toast.success(t("Model deleted successfully"));
      queryClient.invalidateQueries(getModelsQueryPrefix(solutionId));
      queryClient.removeQueries(modelQuery(modelId));
      if (redirect) {
        navigate(getModelsPath(solutionId));
      }
    },
    onError: (error, { model }) => {
      if (error instanceof AxiosError && error.response?.status === 422) {
        try {
          if (onConfirmation) {
            const response = ModelDeletionErrorZod.parse(error.response.data);
            onConfirmation(model, response.values);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        toast.error(t("An error occurred while deleting. Please try again."));
      }
    },
  });
};

async function getModelUsedIdentifiers(
  solutionId: string | number
): Promise<ModelUsedIdentifiers[]> {
  return ModelUsedIdentifiersZod.array().parse(
    (await api.get(`/Prognos/Solutions/${solutionId}/Models/UsedIdentifiers`))
      .data
  );
}

export const modelUsedIdentifiersQuery = (solutionId: string) => ({
  queryKey: [...getModelsQueryPrefix(solutionId).queryKey, "usedIdentifiers"],
  queryFn: () => getModelUsedIdentifiers(solutionId),
});
