import React from "react";

import classNames from "classnames";

interface StackedListProps {
  children: React.ReactNode;
  className?: string;
}

export default function StackedList(props: StackedListProps): JSX.Element {
  const { children, className } = props;

  return (
    <ul
      role="list"
      className={classNames(
        "divide-y divide-gray-200 text-sm font-medium",
        className
      )}
    >
      {children}
    </ul>
  );
}

interface StackedListItemProps {
  id?: string;
  children: React.ReactNode;
  className?: string;
  onClick?(): void;
}

export function StackedListItem(props: StackedListItemProps): JSX.Element {
  const { id, children, className, onClick } = props;
  return (
    <li
      id={id}
      onClick={onClick}
      className={classNames(
        "flex items-center justify-between p-4 hover:bg-gray-50",
        className
      )}
    >
      {children}
    </li>
  );
}
