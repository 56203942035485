import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../api";
import {
  UserSettings,
  UserSettingsZod,
  defaultUserSettings,
} from "../apps/PrognosAI/models/settings";
import { useAccount } from "../components/AuthProvider";
import {
  Account,
  AccountZod,
  CsrfWrapper,
  CsrfWrapperZod,
} from "../models/auth";

const SETTINGS_API = "/Suite/Settings";

async function getCsrfToken(): Promise<CsrfWrapper> {
  return CsrfWrapperZod.parse((await api.get("/csrf")).data);
}

export const csrfTokenQuery = () => {
  return {
    queryKey: ["csrfToken"],
    queryFn: getCsrfToken,
  };
};

async function getAccount(): Promise<Account> {
  return AccountZod.parse((await api.get("/Account")).data);
}

export const accountQuery = () => {
  return {
    queryKey: ["account"],
    queryFn: getAccount,
  };
};

async function getSettings(): Promise<UserSettings> {
  try {
    return UserSettingsZod.parse((await api.get(SETTINGS_API)).data);
  } catch (error) {
    console.error(error);
    return defaultUserSettings;
  }
}

export const settingsQuery = () => {
  const account = useAccount();
  return {
    queryKey: ["settings", account?.user ?? null],
    queryFn: getSettings,
  };
};

async function updateSettings(patch: Partial<UserSettings>) {
  return UserSettingsZod.parse((await api.patch(SETTINGS_API, patch)).data);
}

export const useEditSettings = () => {
  const queryClient = useQueryClient();
  const account = useAccount();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<UserSettings>) => updateSettings(patch),
    onSuccess: (newSettings) => {
      queryClient.setQueryData<UserSettings>(
        ["settings", account?.user ?? null],
        newSettings
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};
