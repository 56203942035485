import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  CogIcon,
  DocumentChartBarIcon,
  HomeIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";

import { SidebarItem } from "../../../components/sidebar/Sidebar";
import {
  getInfluencingFactorsImportPath,
  getInfluencingFactorsPath,
} from "../routes/influencingFactors";
import { getModelsPath } from "../routes/models";
import { getOutliersPath } from "../routes/outliers";
import { getPrognosPath } from "../routes/prognos";
import { getResultsPath } from "../routes/results";
import { getReviewPath } from "../routes/review";
import { getRunsPath } from "../routes/runConfigs";

export default function useSolutionMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const pathPrefix = getPrognosPath(solutionId);
  const { t } = useTranslation();

  const topItems = [
    {
      key: "dashboard",
      name: t("Dashboard"),
      href: pathPrefix,
      icon: HomeIcon,
      current: !!location.pathname.match(/^\/auth\/\d+\/?$/),
    },
    {
      key: "review",
      name: t("Review"),
      href: getReviewPath(solutionId),
      icon: DocumentChartBarIcon,
      current: location.pathname.startsWith(getReviewPath(solutionId)),
    },
    {
      key: "influencingFactors",
      name: t("Influencing factors"),
      href: getInfluencingFactorsPath(solutionId),
      icon: CalendarDaysIcon,
      current:
        location.pathname.startsWith(getInfluencingFactorsPath(solutionId)) ||
        location.pathname.startsWith(
          getInfluencingFactorsImportPath(solutionId)
        ),
    },
    {
      key: "outliers",
      name: t("Outliers"),
      href: getOutliersPath(solutionId),
      icon: ChartBarIcon,
      current: location.pathname.startsWith(getOutliersPath(solutionId)),
    },
    {
      key: "models",
      name: t("Models"),
      href: getModelsPath(solutionId),
      icon: AdjustmentsHorizontalIcon,
      current: location.pathname.startsWith(getModelsPath(solutionId)),
    },
    {
      key: "runConfigurations",
      name: t("Scenarios"),
      href: getRunsPath(solutionId),
      icon: CogIcon,
      current: location.pathname.startsWith(getRunsPath(solutionId)),
    },
    {
      key: "results",
      name: t("Results"),
      href: getResultsPath(solutionId),
      icon: PresentationChartLineIcon,
      current: location.pathname.startsWith(getResultsPath(solutionId)),
    },
  ];

  return [topItems, []];
}
