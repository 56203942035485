import React from "react";
import { useTranslation } from "react-i18next";

import { useSolution } from "../../../api/solutions";
import Alert from "../../../components/Alert";
import Slideover from "../../../components/Slideover";
import CustomDatePicker from "../../../components/form/CustomDatePicker";
import ListCheckbox from "../../../components/form/ListCheckbox";
import ListRadio from "../../../components/form/ListRadio";
import Select from "../../../components/form/Select";
import { dateToDateString } from "../../../models/primitives";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";
import { getLeafPartitions } from "../../PrognosAI/functions/partition";
import { SolutionDetail } from "../../PrognosAI/models/solution";
import SelectablePartitionTree from "../../PrognosAI/pages/Run/components/SelectablePartitionTree";
import { Analysis } from "../api/analysis";

interface DataSettingsProps {
  analysis: Analysis;
  onChange(patch: Partial<Analysis>): void;
  contained?: boolean;
}

export default function DataSettings(props: DataSettingsProps): JSX.Element {
  const { analysis, onChange, contained = false } = props;

  const [{ data: solution }] = useSolution();

  const { t } = useTranslation();

  const showPrecedeError =
    analysis.fromDate && analysis.toDate && analysis.fromDate > analysis.toDate;

  const partitionerOptions =
    solution?.partitioners.map((p) => ({
      key: p.partitionerId,
      value: p.partitionerId,
      label: p.name,
    })) ?? [];

  const measurementOptions =
    solution?.measurements.map((m) => ({
      key: m.measurementId,
      value: m.measurementId,
      label: m.name,
    })) ?? [];
  const usedMeasurement = measurementOptions.find(
    (option) => option.key === analysis.measurementId
  );
  const disabledMeasurements =
    measurementOptions.length === 1 && !!usedMeasurement;

  const showContainedPartitions =
    contained && (!solution || solution.partitions.length > LIST_THRESHOLD + 1);

  return (
    <>
      <NarrowFormItem
        label={t("Time range")}
        htmlFor="fromDate"
        help={t(
          "Specifies the date range from which the selected data will be used."
        )}
        isDefault={!analysis.fromDate && !analysis.toDate}
        forceNarrow={contained}
        standalone={contained}
      >
        <div className="flex items-center space-x-2 text-sm">
          <CustomDatePicker
            id="fromDate"
            value={analysis.fromDate}
            placeholder={t("First data point")}
            error={!!showPrecedeError}
            utc
            onChange={(fromDate) =>
              onChange({ fromDate: dateToDateString(fromDate) })
            }
          />
          <span>{t("to")}</span>
          <CustomDatePicker
            id="toDate"
            value={analysis.toDate}
            placeholder={t("Last data point")}
            error={!!showPrecedeError}
            utc
            onChange={(toDate) =>
              onChange({ toDate: dateToDateString(toDate) })
            }
          />
        </div>
        {showPrecedeError && (
          <p className="text-sm text-red-600">
            {t("The start date must precede the end date.")}
          </p>
        )}
      </NarrowFormItem>
      <NarrowFormItem
        label={t("Differentiate by")}
        htmlFor="diffPartitionerIds"
        forceNarrow={contained}
        standalone={contained}
      >
        <div className="my-2">
          <ListCheckbox
            id="diffPartitionerIds"
            options={partitionerOptions}
            value={partitionerOptions.filter((option) =>
              analysis.diffPartitionerIds.includes(option.key)
            )}
            onChange={(values) =>
              onChange({ diffPartitionerIds: values.map((val) => val.key) })
            }
          />
        </div>
      </NarrowFormItem>
      <NarrowFormItem
        label={t("Observable")}
        htmlFor="measurementId"
        isDefault={disabledMeasurements}
        forceNarrow={contained}
        standalone={contained}
      >
        {!contained && (
          <div className="my-2">
            <ListRadio
              id="measurementId"
              options={measurementOptions}
              value={usedMeasurement ?? null}
              disabled={disabledMeasurements}
              onChange={({ key }) => onChange({ measurementId: key })}
            />
          </div>
        )}
        {contained && (
          <Select
            id="measurementId"
            options={measurementOptions}
            value={usedMeasurement ?? null}
            disabled={disabledMeasurements}
            onChange={({ key }) => onChange({ measurementId: key })}
          />
        )}
      </NarrowFormItem>
      <NarrowFormItem
        id="planningAreasSection"
        label={t("Planning Areas")}
        htmlFor="planningAreasPicker"
        forceNarrow={contained}
        standalone={contained}
      >
        {!!solution && (
          <>
            {!showContainedPartitions && (
              <SelectablePartitionTree
                id="runPartitionsPicker"
                partitioners={solution.partitioners}
                partitions={solution.partitions}
                usedPartitions={analysis.partitionIds}
                defaultOpen
                forcedView="leaves"
                onChange={(partitionIds) => onChange({ partitionIds })}
              />
            )}
            {showContainedPartitions && (
              <ContainedPartitions
                solution={solution}
                analysis={analysis}
                onChange={onChange}
              />
            )}
          </>
        )}
      </NarrowFormItem>
    </>
  );
}

type ContainedPartitionsProps = {
  solution: SolutionDetail;
  analysis: Analysis;
  onChange(patch: Partial<Analysis>): void;
};

function ContainedPartitions(props: ContainedPartitionsProps): JSX.Element {
  const { solution, analysis, onChange } = props;

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="text-sm">
        <PartitionsList {...props} />
        <p className="text-right">
          <button
            className="underline text-blue-600"
            onClick={() => setOpen(true)}
          >
            {t("Change")}
          </button>
        </p>
      </div>
      <Slideover
        title={t("Select planning areas")}
        open={open}
        onClose={() => setOpen(false)}
      >
        <SelectablePartitionTree
          id="runPartitionsPicker"
          partitioners={solution.partitioners}
          partitions={solution.partitions}
          usedPartitions={analysis.partitionIds}
          defaultOpen
          forcedView="leaves"
          onChange={(partitionIds) => onChange({ partitionIds })}
        />
      </Slideover>
    </>
  );
}

const LIST_THRESHOLD = 5;

type PartitionsListProps = {
  solution: SolutionDetail;
  analysis: Analysis;
};

function PartitionsList(props: PartitionsListProps): JSX.Element {
  const { solution, analysis } = props;

  const { t } = useTranslation();

  if (analysis.partitionIds.length === 0) {
    return <Alert type="warning">{t("No planning area is selected.")}</Alert>;
  }

  const allLeaves = getLeafPartitions(
    solution.partitions,
    solution.partitioners
  );
  const unselectedLeaves = allLeaves.filter(
    (leaf) => !analysis.partitionIds.includes(leaf.partitionId)
  );

  if (unselectedLeaves.length === 0) {
    return (
      <Alert type="info">
        {t("All planning areas are selected.", {
          count: analysis.partitionIds.length,
        })}
      </Alert>
    );
  }

  const selectedLeaves = allLeaves.filter((leaf) =>
    analysis.partitionIds.includes(leaf.partitionId)
  );
  if (selectedLeaves.length < LIST_THRESHOLD) {
    return (
      <>
        <Alert type="info">{t("Selected planning areas:")}</Alert>
        <ul className="list-outside pl-6 list-disc">
          {selectedLeaves.map((leaf) => (
            <li key={leaf.partitionId} className="pl-1 my-1">
              {leaf.fullName}
            </li>
          ))}
        </ul>
      </>
    );
  }

  if (unselectedLeaves.length < LIST_THRESHOLD) {
    return (
      <>
        <Alert type="warning">{t("Omitted planning areas:")}</Alert>
        <ul className="list-outside pl-6 list-disc">
          {unselectedLeaves.map((leaf) => (
            <li key={leaf.partitionId} className="pl-1 my-1">
              {leaf.fullName}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Alert type="info">
      {t("Total {{count}} planning areas are selected.", {
        count: analysis.partitionIds.length,
      })}
    </Alert>
  );
}
