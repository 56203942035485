import React from "react";
import { RouteObject } from "react-router-dom";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { userSettingsRoute } from "../../PrognosAI/routes/userSettings";
import DataStoreLayout from "../components/layouts/DataStoreLayout";
import { businessHoursRoute } from "./businessHours";
import { DATA_STORE_PATH } from "./dataStore";
import { importRoute } from "./import";

const Dashboard = lazyWithRetry(() => import("../pages/Dashboard/Dashboard"));

export const dataStoreRoute: RouteObject = {
  path: DATA_STORE_PATH,
  element: <DataStoreLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    importRoute,
    businessHoursRoute,
    userSettingsRoute,
  ],
};
