import React from "react";

import Hero from "../Hero";

export default function SkeletonHero(): JSX.Element {
  return (
    <Hero
      cardClassName="border-2 border-gray-200 bg-transparent shadow-none"
      actions={<div className="h-7 bg-gray-200 rounded-full w-20"></div>}
    >
      <div className="h-7 bg-gray-200 rounded-full w-48"></div>
    </Hero>
  );
}
