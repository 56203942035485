import React from "react";
import { useTranslation } from "react-i18next";

import { ListBulletIcon, TrashIcon } from "@heroicons/react/20/solid";

import Number from "../../apps/PrognosAI/components/formatter/Number";

interface DataTableBatchControlProps {
  id: string;
  className?: string;
  selectedCount: number;
  selectedAll: boolean;
  onDelete(): void;
  onSelectAll?(): void;
}

export default function DataTableBatchControl(
  props: DataTableBatchControlProps
): JSX.Element | null {
  const { id, className = "" } = props;
  const { selectedCount, selectedAll } = props;
  const { onDelete, onSelectAll } = props;

  const { t } = useTranslation();

  if (!selectedAll && selectedCount === 0) {
    return null;
  }

  return (
    <div id={id} className={`text-xs flex items-center space-x-4 ${className}`}>
      <p>
        {t("Selected rows")}:{" "}
        {selectedAll ? t("all (all pages)") : <Number value={selectedCount} />}
      </p>
      {!!onSelectAll && (
        <button
          id={`${id}-selectAll`}
          onClick={onSelectAll}
          className="underline text-blue-500 flex items-center space-x-1"
        >
          <ListBulletIcon className="w-3 h-3" />
          <span>{t("Select all rows in all pages")}</span>
        </button>
      )}
      <button
        id={`${id}-deleteSelected`}
        onClick={onDelete}
        className="underline text-blue-500 flex items-center space-x-1"
      >
        <TrashIcon className="w-3 h-3" />
        <span>{t("Delete selected rows")}</span>
      </button>
    </div>
  );
}
