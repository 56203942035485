import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getAnalysisPath } from "./analyzer";

const TimeSeries = lazyWithRetry(
  () => import("../pages/TimeSeries/TimeSeries")
);

const TIME_SERIES_PATH = "timeSeries";

export const timeSeriesRoute: RouteObject = {
  path: TIME_SERIES_PATH,
  element: <TimeSeries />,
};

export function getTimeSeriesPath(
  solutionId: string | number,
  analysisId: string | number
): string {
  return `${getAnalysisPath(solutionId, analysisId)}/${TIME_SERIES_PATH}`;
}
