import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  HomeIcon,
  QueueListIcon,
  ShareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, { SidebarItem } from "../../../../components/sidebar/Sidebar";
import { getSolutionsPath } from "../../../../routes/solutions";
import BasicLayout from "../../../PrognosAI/components/layouts/BasicLayout";
import { getUserSettingsPath } from "../../../PrognosAI/routes/userSettings";
import { getBusinessMapPath } from "../../routes/businessMap";
import { getDemandPath } from "../../routes/demand";

interface DemandSolutionLayoutProps {
  children?: React.ReactNode;
}

export default function DemandSolutionLayout({
  children,
}: DemandSolutionLayoutProps) {
  const [{ data: solution }, solutionId] = useSolution();

  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems(solutionId);

  const topNavigation = [projectTop, solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}

function useGeneralMenuItems(
  solutionId?: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();
  const pathPrefix = getSolutionsPath();

  const topNavigation: SidebarItem[] = [
    {
      key: "solutions",
      name: t("Projects"),
      href: pathPrefix,
      icon: QueueListIcon,
      current: location.pathname.endsWith(pathPrefix),
    },
  ];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, "Demand"),
      icon: UserIcon,
      current: location.pathname.startsWith(
        getUserSettingsPath(solutionId, "Demand")
      ),
    },
  ];

  return [topNavigation, bottomNavigation];
}

function useSolutionMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const pathPrefix = getDemandPath(solutionId);
  const { t } = useTranslation();

  const topItems = [
    {
      key: "dashboard",
      name: t("Overview"),
      href: pathPrefix,
      icon: HomeIcon,
      current: location.pathname === getDemandPath(solutionId),
    },
    {
      key: "businessMap",
      name: t("Business map"),
      href: getBusinessMapPath(solutionId),
      icon: ShareIcon,
      current: location.pathname.startsWith(getBusinessMapPath(solutionId)),
    },
  ];

  return [topItems, []];
}
