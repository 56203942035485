import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import { useSolution } from "../../../../../api/solutions";
import CircularLoading from "../../../../../components/CircularLoading";
import HeroActionButton from "../../../../../components/HeroActionButton";
import { ButtonProps } from "../../../../../components/buttons/Button";
import {
  importExportConfigQuery,
  useStartExport,
} from "../../../../PrognosAI/api/importExport";
import { activeExportTasksQuery } from "../../../../PrognosAI/api/tasks";
import AlertDialog from "../../../../PrognosAI/components/dialogs/AlertDialog";
import {
  ImportExportConfig,
  ImportExportEntity,
} from "../../../../PrognosAI/models/importExport";
import { SolutionDetail } from "../../../../PrognosAI/models/solution";
import StandardExportButton from "./StandardExportButton";

interface EntityExportButtonProps {
  entity: ImportExportEntity;
  type?: "hero" | "standard";
  variant?: ButtonProps["variant"];
  hideIcon?: boolean;
  config?: Partial<ImportExportConfig>;
  className?: string;
}

export default function EntityExportButton(
  props: EntityExportButtonProps
): JSX.Element {
  const { entity, type = "hero", className } = props;
  const { config = {}, variant = "white", hideIcon } = props;

  const [{ data: solution }, solutionId] = useSolution();

  const { t } = useTranslation();

  const { onStart, progress, importExportConfig } = useEntityExport(
    solutionId,
    solution,
    entity,
    config
  );

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = () => {
    if (onStart) {
      onStart();
    }
    setOpenDialog(true);
  };

  const title = useTitle(entity);

  return (
    <>
      {type === "hero" && (
        <HeroActionButton
          title={title}
          onClick={handleClick}
          className={className}
          disabled={openDialog}
        >
          <ArrowDownTrayIcon />
        </HeroActionButton>
      )}
      {type === "standard" && (
        <StandardExportButton
          onClick={handleClick}
          className={className}
          disabled={openDialog}
          variant={variant}
          hideIcon={hideIcon}
        >
          {title}
        </StandardExportButton>
      )}
      <AlertDialog
        id={`${entity}-exportDialog`}
        type="info"
        title={title}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <div className="w-full flex flex-col space-y-2">
          {!!importExportConfig?.downloadUrl && (
            <p>
              {t("The file is ready for download:")}{" "}
              <a
                href={importExportConfig.downloadUrl}
                download
                className="inline-flex items-center space-x-1"
              >
                <ArrowDownTrayIcon className="w-3 h-3" />
                <span>{t("Download")}</span>
              </a>
            </p>
          )}
          {!importExportConfig?.downloadUrl && (
            <div className="flex items-center space-x-2">
              <CircularLoading size={20} className="text-blue-400" />
              <p>
                {t("The file is being prepared: {{progress}}% finished", {
                  progress: progress ?? 0,
                })}
              </p>
            </div>
          )}
        </div>
      </AlertDialog>
    </>
  );
}

function useTitle(entity: ImportExportEntity): string {
  const { t } = useTranslation();

  switch (entity) {
    case "BusinessHours":
      return t("Export business hours");
    case "InfluencingFactors":
      return t("Export influencing factors");
    case "Result":
      return t("Export result");
    case "IntradayProfiles":
      return t("Export intraday profiles");
    case "History":
      return t("Export history data");
  }
}

function useFileName(entity: ImportExportEntity): string {
  const { t } = useTranslation();

  switch (entity) {
    case "BusinessHours":
      return t("Business hours");
    case "InfluencingFactors":
      return t("Influencing factors");
    case "Result":
      return t("Result");
    case "IntradayProfiles":
      return t("Intraday profiles");
    case "History":
      return t("History data");
  }
}

function useEntityExport(
  solutionId: string,
  solution: SolutionDetail | undefined,
  entity: ImportExportEntity,
  defaults: Partial<ImportExportConfig>
): {
  onStart?(): void;
  progress?: number;
  importExportConfig?: ImportExportConfig;
} {
  const fileName = useFileName(entity);

  const [configId, setConfigId] = React.useState<number>();

  const { data: { items: tasks = [] } = {} } = useQuery({
    ...activeExportTasksQuery(solutionId, `Export${entity}`, configId),
    enabled: !!configId,
  });

  const { data: config } = useQuery({
    ...importExportConfigQuery(configId ?? 0),
    enabled: !!configId,
  });

  let progress: number | undefined = undefined;
  const progresses = tasks.flatMap((t) =>
    typeof t.progress === "number" ? [t.progress] : []
  );
  if (progresses.length > 0) {
    progress = Math.min(...progresses);
  }

  const startExport = useStartExport(solutionId);
  const handleExportStart = async () => {
    setConfigId(undefined);
    const newConfig = await startExport.mutateAsync({
      entity: entity,
      fileName: `${solution?.name ?? ""} - ${fileName}`,
      includeHistory: false,
      timestampZone: "Utc",
      ...defaults,
    });
    setConfigId(newConfig.importExportConfigId);
  };

  const importExportConfig = configId ? config : undefined;
  if (typeof progress === "number") {
    return { progress, importExportConfig };
  }

  return { onStart: handleExportStart, importExportConfig };
}
